import { login, login1, getuserlist } from '@/api/index'


const state = {
    userlist: {},
    cardLogin: {},
    userLogin: {},
    userId: '',
    userName: '',
    loginState: {
        state: false,
        islogin: false,
    },
    firstLogin: false,
    driver: 0,
    setBatchId: '',
    isVip: 0,
    opVip: 0,
    comScore: 0,
}
const mutations = {
    comScore(state, comScore) {
        state.comScore = state.comScore + comScore
    },
    OPENVIP(state, opVip) {
        state.opVip = opVip
    },
    setBatchId(state, batchId) {
        state.setBatchId = batchId
    },
    Driver(state, driver) {
        state.driver = driver
    },
    setLoginState(state, loginState) {
        // localStorage.setItem('loginState', loginState)
        state.loginState.state = loginState.state,
            state.loginState.islogin = loginState.islogin
    },
    setUserList(state, username) {
        localStorage.setItem('username', username)
        state.userName = localStorage.getItem('username')
    },
    USERLOGIN(state, userLogin) {

        state.userLogin = userLogin
        state.userId = userLogin.user_id
        localStorage.setItem('userLogin', JSON.stringify(userLogin))
        localStorage.setItem('accessToken', userLogin.accessToken)
        localStorage.setItem('userId', userLogin.user_id)
    },
    CORDLOGIN(state, cardLogin) {
        state.cardLogin = cardLogin
        state.userId = cardLogin.user_id
        localStorage.setItem('cardLogin', JSON.stringify(cardLogin))
        localStorage.setItem('accessToken', cardLogin.accessToken)
        localStorage.setItem('userId', cardLogin.user_id)
    },
    USERLIST(state, userlist) {
        console.log(1111111111111, userlist);

        state.userlist = userlist
    },
    firstLogin(state, firstLogin) {
        state.firstLogin = firstLogin
        state.driver = 1
    },
    isVip(state, isVip) {
        state.isVip = isVip
    }

}
const actions = {
    async userLogin({ commit }, data) {
        let result = await login(data)
        if (result.code === 200) {
            // window.location.reload()
            commit("USERLOGIN", result.data);
            commit('setLoginState', { state: false, islogin: true })
            if (result.data.isFirstLogin == true) {
                commit('firstLogin', true)
                commit('Driver', 1)
            }

            return "ok"
        } else {

            return Promise.reject(result.msg)
            // return Promise.reject(new Error(result.msg))
        }
    },
    async cardLogin({ commit }, data) {
        // console.log('data1', data);
        let result = await login1(data)
        // console.log('cardLogin', result.data);
        if (result.code === 200) {
            commit("CORDLOGIN", result.data);
            commit('setLoginState', { state: false, islogin: true })
            if (result.data.isFirstLogin == true) {
                commit('firstLogin', true)
                commit('Driver', 1)
            }
            //    window.location.reload()
        } else {
            // return result.msg
            return Promise.reject(result.msg)
        }
    },
    /* async getUserList({ commit }, userInfo) {
        // console.log('userInfo', userInfo);
        let result = await getuserlist(userInfo)
        if (result.code === 200) {
            commit("USERLIST", result.data);
            return result.data
        } else {
            return Promise.reject(result.msg)
        }
    } */
}
const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}