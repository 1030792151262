<template>
  <div class="arithmetic" v-if="mainIndex == 1">
    <div class="title">校考日历</div>
    <div class="calendar">
      <el-calendar v-model="dateValue" @input="handleInput"></el-calendar>
    </div>
    <div class="list">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="正在报名院校" name="first" key="123">
          <div
            style="text-align: center; padding: 20px"
            v-if="appLyingList.length == 0"
          >
            {{ date }}暂无报名院校
          </div>
          <div
            class="school-list"
            v-for="(item, index) in appLyingList"
            :key="index"
          >
            <div class="school-name">{{ item.collegeName }}</div>
            <div class="school-desc">{{ item.dataDesc }}</div>
            <i
              class="el-icon-arrow-right icon"
              @click="
                toDetial(item.examSubject, item.schoolExamInfo, item.examTime)
              "
            ></i>
          </div>
        </el-tab-pane>
        <el-tab-pane label="线下考点" name="second">
          <div
            style="text-align: center; padding: 20px"
            key="234"
            v-if="offlineList.length == 0"
          >
            {{ date }}暂无线下考点
          </div>
          <div class="list">
            <div
              class="list-offline"
              v-for="(item, index) in offlineList"
              :key="index"
            >
              <div class="list-title">
                {{ item.alphabet }}&nbsp;{{ item.provinceName }}
                <span>地区</span>
              </div>
              <div
                class="list-box"
                v-for="(item1, index) in item.item"
                :key="index"
              >
                <div class="left">{{ item1.cityArea }}</div>
                <div class="middle">
                  <div style="font-size: 20px; margin-bottom: 10px">
                    {{ item1.collegeName }}
                    <span>({{ item1.applySchoolSum }}所可报)</span>
                  </div>
                  <div>
                    <template v-if="item1.startDate && item1.endDate">
                      考试时间&nbsp;
                      {{ item1.startDate }}至{{ item1.endDate }} &nbsp;{{
                        item1.examTimeInfo
                      }}
                    </template>
                  </div>
                </div>
                <div class="right">
                  <i
                    class="el-icon-arrow-right"
                    style="cursor: pointer"
                    @click="toDetial(item1.examInfo)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="线上考试院校" name="third">
          <div
            style="text-align: center; padding: 20px"
            key="789"
            v-if="onlineList.length == 0"
          >
            {{ date }}暂无线上考点
          </div>
          <div class="online" v-for="(item, index) in onlineList" :key="index">
            <div class="left">
              <div class="school-name">{{ item.collegeName }}</div>
              <div class="school-time">
                考试时间&nbsp; {{ item.startDate }}至{{ item.endDate }} &nbsp;{{
                  item.examTimeInfo
                }}
              </div>
            </div>
            <div class="right">
              <i
                class="el-icon-arrow-right"
                style="cursor: pointer"
                @click="toDetial(item.examInfo)"
              ></i>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="detail" v-else>
    <div style="margin-bottom: 20px; cursor: pointer" @click="back">
      <i class="el-icon-arrow-left"></i>返回上级
    </div>
    <div
      class="noadssion"
      style="
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      v-if="detail.examTime"
    >
      考试时间：<span style="color: red">{{ detail.examTime }}</span>
    </div>
    <div class="card_group_title" v-if="detail.examInfo">
      <img src="@/assets/images/collegedetail/icon_title.png" />
      校考信息
    </div>
    <div
      class="noadssion"
      v-html="detail.examInfo"
      style="margin-bottom: 30px"
    ></div>
    <div class="card_group_title" v-if="detail.examInfo">
      <img src="@/assets/images/collegedetail/icon_title.png" />
      考试科目
    </div>
    <div class="noadssion" v-html="detail.examSubject"></div>
  </div>
</template>

<script>
import { getApplyingList, getOnlineList, getOfflineList } from "@/api/index";
export default {
  name: "SchoolCalendar",
  data() {
    return {
      dateValue: new Date(),
      activeName: "first",
      appLyingList: [],
      onlineList: [],
      offlineList: [],
      detail: {
        examInfo: "",
        examSubject: "",
        examTime: "",
      },
      mainIndex: 1,
      date: "",
    };
  },
  mounted() {
    this.handleInput(this.dateValue);
  },
  methods: {
    back() {
      this.mainIndex = 1;
    },
    toDetial(examSubject, schoolExamInfo, examTime) {
      this.detail.examInfo = schoolExamInfo;
      this.detail.examSubject = examSubject;
      this.detail.examTime = examTime;
      this.mainIndex = 2;
    },
    handleClick(tab, event) {},
    handleInput(value) {
      // console.log("dateValue--", value);
      const formattedDate =
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        value.getDate().toString().padStart(2, "0");
      console.log("Formatted Date--", formattedDate);
      this.date = formattedDate;
      getApplyingList(formattedDate).then((res) => {
        console.log(res);
        this.appLyingList = res.data;
      });
      getOnlineList(formattedDate).then((res) => {
        console.log(res);
        this.onlineList = res.data;
      });
      getOfflineList(formattedDate).then((res) => {
        console.log(res);
        this.offlineList = res.data;
      });
    },
    arithmeticForm() {
      console.log("fto--", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  
  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .calendar {
    width: 100%;
    height: 640px;

    ::v-deep .el-calendar__header {
      padding-left: 0;
    }
  }
  .list {
    width: 100%;
    .school-list {
      display: flex;
      align-items: center;
      height: 50px;
      position: relative;
      border-top: #888888 1px solid;
      &:first-child {
        border-top: none;
      }
      .icon {
        position: absolute;
        right: 0px;
        cursor: pointer;
      }
      .school-name {
        font-size: 18px;
        color: #242424;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        margin-right: 10px;
      }
      .school-desc {
        font-size: 14px;
        color: #888888;
        font-weight: 400;
      }
    }
    .list-offline {
      .list-title {
        font-size: 20px;
        span {
          font-size: 14px;
          color: #888888;
        }
      }
      .list-box {
        display: flex;
        height: 50px;
        padding: 20px;
        border: #eee solid 0.1px;
        margin-top: 10px;
        align-items: center;
        position: relative;
        .left {
          width: 80px;
        }
        .middle {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            color: red;
            font-size: 14px;
          }
        }
        .right {
          position: absolute;
          right: 20px;
        }
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
    .online {
      display: flex;
      align-items: center;
      position: relative;
      padding: 20px;
      border: #eee solid 0.1px;
      .left {
        .school-name {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .right {
        position: absolute;
        right: 20px;
      }
    }
  }
}
.detail {
  padding: 10px;
  .noadssion ::v-deep p {
    img{
      width: 100%;
    }
  }

  .card_group_title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 20px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}
</style>
