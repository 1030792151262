<template>
  <div class="college-detail">
    <div class="card_group_title" v-if="examSchool.length > 0">
      <img src="@/assets/images/collegedetail/icon_title.png" />
      招生方向
    </div>
    <div class="list" v-for="item in examSchool" :key="item.group">
      <div v-if="item.group">{ {{ item.group }} } {{ item.collegeName }}</div>
      <div
        class="list-content"
        v-for="(item1, index1) in item.item"
        :key="index1"
      >
        <div class="list-content-left">
          <div class="list-title">{{ item1.batch }}</div>
          <div class="list-content-item">
            {{ item1.major }}
            <span style="margin-left: 10px;color: gray;" v-for="item2 in item1.tags" :key="item2">{{ item2? '('+item2+')' : ''}}</span>
          </div>
        </div>
        <div class="list-content-right">
          <div
            class="probability"
            :style="{
              color: item1.probability === null ? '#999' : '#ef5a2b',
              fontSize: item1.probability === null ? '16px' : '34px',
            }"
          >
            {{
              item1.probability === null ? "暂无概率" : item1.probability + "%"
            }}
          </div>
          <el-button
            class="addSchoolExam"
            v-if="item1.canCalculate == 1 && item1.probability == null"
            @click="addSchoolExam(item.collegeName, item1)"
            >+添加校考院校</el-button
          >

          <el-button
            v-if="item1.canCalculate == 1 && item1.probability != null"
            class="addSchoolExam"
            @click="toDetail(item1.recordId)"
            >查看分析</el-button
          >
        </div>
      </div>
    </div>
    <div class="showCard" v-if="showForm">
      <el-form ref="form" :model="form" :rules="rules">
        <div class="form-title">
          校考成绩管理
          <i @click="showForm = false" class="el-icon-close close"></i>
        </div>
        <el-form-item
          style="margin-left: 10px; margin-top: 30px"
          label="院校名称"
        >
          <el-input v-model="form.school.label" disabled></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px" label="报考专业">
          <el-input v-model="form.major.label" disabled></el-input>
        </el-form-item>
        <el-form-item label="校考成绩" prop="score">
          <el-input v-model="form.score"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px" label="校考排名" prop="rank">
          <el-input v-model="form.rank"></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitForm('form')"
          >立即添加</el-button
        >
      </el-form>
    </div>
  </div>
</template>
<script>
import { getEnrollmentDirection, addStudentSchoolExam } from "@/api";
export default {
  name: "schoolExam",
  data() {
    return {
      showForm: false,
      form: {
        school: { label: "", value: "" },
        major: { label: "", value: "" },
        score: "",
        rank: "",
      },
      rules: {
        /* school: [
          { required: true, message: "请选择校考院校", trigger: "change" },
        ],
        major: [
          { required: true, message: "请选择校考院校", trigger: "change" },
        ], */
        score: [
          { required: true, message: "请输入校考成绩", trigger: "blur" },
          {
            pattern: /^-?\d+(\.\d{1,2})?$/,
            message: "请输入有效的浮点数（最多两位小数）",
            trigger: "blur",
          },
        ],
      },
      examSchool: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    toDetail(id) {
      let college = this.$route.query.id;
      window.open(`/schoolExamDetail?id=${id}&college=${college}`, "_blank");
    },
    getData() {
      //   console.log("college", this.$route.query.id);
      let college = this.$route.query.id;
      let student = JSON.parse(localStorage.getItem("studentDetail"))?.recordId;
      getEnrollmentDirection(college, student).then((res) => {
        this.examSchool = res.data;
      });
    },
    addSchoolExam(school, value) {
      this.showForm = true;
      console.log(value, 111111);
      this.form.major.label = value.major;
      this.form.major.value = value.recordId;
      this.form.school.label = school;
      this.form.school.value = this.$route.query.id;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid, info) => {
        if (valid) {
          console.log("submit!");
          let student = JSON.parse(
            localStorage.getItem("studentDetail")
          )?.recordId;
          let data = {
            enrollDirection: this.form.major.value,
            student: student,
            schoolExamScore: this.form.score,
            schoolExamRank: this.form.rank,
          };
          addStudentSchoolExam(data).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.showForm = false;
              this.getData();
            }
          });
        } else {
          console.log(info);
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.college-detail {
  .card_group_title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 20px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    // margin-bottom: 20px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
  .list {
    margin-top: 20px;
    .list-content {
      height: 60px;
      margin-top: 20px;
      background: #fff;
      padding: 20px;
      width: 1240px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list-content-left {
        width: 900px;
        .list-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 18px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 10px;
        }
        .list-content-item {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 18px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 10px;
        }
      }
      .list-content-right {
        width: 340px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addSchoolExam {
          border: 1px solid #ef5a2b;
          color: #ef5a2b;
        }
      }
    }
  }
  .showCard {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .el-form {
      width: 700px;
      height: auto;
      background: #fff;
      padding: 20px;
      .el-form-item {
        display: flex;
      }
      .form-title {
        font-size: 20px;
        font-weight: bold;

        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }
}
</style>