<template>
  <div class="modal">
    <div class="modal-content" style="position: relative">
      <i
        class="el-icon-close"
        @click="close"
        style="
          position: absolute;
          right: 20px;
          top: 20px;
          font-size: 20px;
          color: #000;
          z-index: 999;
        "
      ></i>
      <div class="login-top">
        <!-- <div class="top-title">
          <span class="title-text">
            <img src="@/assets/images/topSearch/textImage.png" alt="" />
            <div>优艺升志愿填报</div></span
          >
        </div> -->
        <div class="top-centent">
          <div :class="active == 2 ? 'active' : ''" @click="actives(2)">
            会员卡登录
          </div>
          <div :class="active == 1 ? 'active' : ''" @click="actives(1)">
            短信快捷登录
          </div>
        </div>
      </div>
      <div class="login-bottom" v-if="active == 1">
        <div>
          <input
            type="text"
            class="phone"
            v-model.trim="phone"
            placeholder="请输入手机号"
            maxlength="11"
          />
        </div>
        <div>
          <input
            type="text"
            class="msg"
            v-model.trim="captcha"
            placeholder="请输入验证码"
            maxlength="6"
          />
          <el-button
            class="msg-but"
            @click="smss(phone)"
            :disabled="countdown > 0"
          >
            {{ countdown > 0 ? `${countdown}s后重新获取` : "获取验证码" }}
          </el-button>
        </div>
        <div>
          <el-button class="login-but" @click="login1()">{{
            "登录"
          }}</el-button>
        </div>
      </div>
      <div class="login-bottom" v-if="active == 2">
        <div>
          <input
            type="text"
            class="phone"
            v-model.trim="cardNbr"
            placeholder="请输入会员号"
          />
        </div>
        <div style="position: relative">
          <input
            :type="show ? 'text' : 'password'"
            class="msg1 el-icon-view"
            v-model.trim="cardKey"
            placeholder="请输入密码"
          />
          <div @click="showPwd" :class="show ? 'show' : 'hide'"></div>
        </div>
        <div>
          <el-button class="login-but" @click="login()">登录</el-button>
        </div>
      </div>
      <div class="info">
        未注册手机验证后自动登录，登录或注册即代表同意
        <a @click="toPage(1)">用户协议</a> 和
        <a @click="toPage(2)">隐私政策</a>
      </div>
    </div>
  </div>
</template>
   
  <script>
import { sms } from "@/api/index.js";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /*  phone: "17788993966",
      captcha: "123456",*/
      cardNbr: "21000993",
      cardKey: "884747",
      phone: "",
      captcha: "",
      cardNbr: "",
      cardKey: "",
      active: 2,

      countdown: 0,

      show: false,
    };
  },
  mounted() {},
  methods: {
    toPage(e) {
      if (e == 1) {
        window.open("/userAgreement", "_blank");
      } else if (e == 2) {
        window.open("/privacyPolicy", "_blank");
      }
    },
    showPwd() {
      this.show = !this.show;
    },
    close() {
      this.active = 2;
      this.$store.commit("setLoginState", { state: false, islogin: false });
      this.countdown = 0;
      clearInterval(this.timer);
      this.$router.push({ name: "home" });
    },
    actives(e) {
      this.active = e;
      console.log(this.active);
    },
    // 验证码
    smss() {
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.phone)) {
        this.$notify({
          title: "提示！",
          type: "error",
          message: "请输入有效的手机号码！",
          showClose: false,
        });
        return;
      }
      sms(this.phone).then((res) => {
        if (res.code == 200) {
          this.countdown = 60;
          this.$notify({
            title: "提示！",
            type: "success",
            message: res.msg,
            showClose: false,
          });
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$notify({
            title: "提示！",
            type: "error",
            message: res.msg,
            showClose: false,
          });
        }
      });
    },
    // 电话号登录
    async login1() {
      try {
        const { phone, captcha } = this;
        if (!phone) {
          this.$notify({
            title: "提示！",
            type: "error",
            message: "请输入手机号",
            showClose: false,
          });
          return;
        } else {
          const phoneRegex = /^1[3-9]\d{9}$/;
          if (!phoneRegex.test(phone)) {
            this.$notify({
              title: "提示！",
              type: "error",
              message: "请输入有效的手机号码！",
              showClose: false,
            });
            return;
          }
        }
        if (!captcha) {
          this.$notify({
            title: "提示！",
            type: "error",
            message: "请输入验证码！",
            showClose: false,
          });
          return;
        }
        if (phone && captcha) {
          await this.$store.dispatch("userLogin", { phone, captcha });
          this.countdown = 0;
        }
        this.active = 1;

        this.$bus.$emit("user", true);
        // this.$emit("success", false);
      } catch (error) {
       this.$notify({
                title: '登录失败',
                message: error,
                type: 'error'
            })
      }
    },
    // 卡号登录
    async login() {
      try {
        const { cardNbr, cardKey } = this;
        if (!cardNbr) {
          this.$notify({
            title: "提示！",
            type: "error",
            message: "请输入会员号！",
            showClose: false,
          });
          return;
        }
        if (!cardKey) {
          this.$notify({
            title: "提示！",
            type: "error",
            message: "请输入密码！",
            showClose: false,
          });
          return;
        }
        if (cardNbr && cardKey) {
          await this.$store.dispatch("cardLogin", { cardNbr, cardKey });
        }
        this.active = 2;

        this.$bus.$emit("user", true);
        this.$emit("success", false);
      } catch (error) {
        console.log(123123123);

        this.$notify({
          title: "提示！",
          type: "error",
          message: error,
          showClose: false,
        });
      }
    },
  },
};
</script>
   
<style scoped lang="scss">
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  /* overflow: hidden; */
}

.modal-content {
  width: 490px;
  height: 410px;
  background: #fff;
  border-radius: 20px 20px 20px 20px;
  .login-top {
    width: 490px;
    height: 70px;
    line-height: 70px;
    // background: linear-gradient(180deg, #f8a68d 0%, #ffffff 100%);
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;

    
    .top-centent {
      display: flex;
      font-size: 16px;
      color: black;
      // margin-top: 40px;
      .active {
        color: #ef5a2b;
        font-weight: bold;
      }
      div {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .login-bottom {
    border-top: 1px #bfbfbf solid;
    width: 490px;
    height: 258px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .phone {
      width: 390px;
      height: 45px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #707070;
      // text-align: center;
      padding-left: 20px;
      margin: 20px 40px;
      font-size: 16px;
    }
    .msg {
      width: 260px;
      height: 45px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #707070;
      margin-left: 40px;
      font-size: 16px;
     
      padding-left: 20px;
      // text-align: center;
    }
    .msg1 {
      width: 390px;
      height: 45px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #707070;
      // text-align: center;
      padding-left: 20px;
      margin-left: 40px;
      font-size: 16px;
    }
    .show {
      width: 32px;
      height: 32px;
      background: url(../../../../assets/images/login/xianshi.png);
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 70px;
      transform: translateY(-50%);
    }
    .hide {
      width: 32px;
      height: 32px;
      background: url(../../../../assets/images/login/yincang.png);
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 70px;
      transform: translateY(-50%);
    }
    .msg-but {
      width: 120px;
      height: 45px;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #ef5a2b;
      margin-left: 10px;
      color: #ef5a2b;
      padding: 15px 5px;
      font-size: 16px;
    }
    .login-but {
      width: 410px;
      height: 45px;
      background: #ef5a2b;
      border-radius: 10px 10px 10px 10px;
      color: #ffffff;
      font-size: 16px;
      margin: 20px 40px;
    }
  }
  .info {
    width: 390px;
    color: black;
    // text-align: center;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 40px;
    // padding-bottom: 10px;
    a {
      color: #ef5a2b;
      cursor: pointer;
    }
  }
}
</style>