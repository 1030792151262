<template>
  <div class="arithmetic">
    <div class="title">录取结果查询</div>
    <div class="table">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="志愿方案">
              <el-select
                v-model="form.wishingId"
                placeholder="请选择志愿方案"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in wishingList"
                  :key="item.recordId"
                  :label="item.wishName"
                  :value="item.recordId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="高考年份">
              <el-input v-model="form.yearly" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="12" class="form_item_left">
            <el-form-item label="艺考方向">
              <el-input v-model="form.artWard" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="综合分数">
              <el-input v-model="form.comScore" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="admissionQuery"
          >查&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
        >
      </el-form>
    </div>

    <div class="el_table" v-if="resultList.length > 0">
      <el-table :data="resultList" style="width: 100%">
        <el-table-column
          prop="collegeName"
          label="录取院校"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="majorName"
          label="录取专业"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="yearlyName"
          label="年份"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="firstMajorName"
          label="首选科目要求"
          header-align="center"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="otherMajorName"
          label="其他科目要求"
          header-align="center"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="comScore"
          label="最低录取分数"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="isAdmission"
          label="是否达线"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span
              :style="{
                color: scope.row.isAdmission === 1 ? '#008000' : '#ef5a2b',
              }"
              >{{ scope.row.isAdmission === 1 ? "达线" : "不达线" }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="noadssion" v-else-if="qflag">暂无录取结果,请稍后重试</div>
    <Loading  style="margin-top: 50px;" v-if="loading"></Loading>
  </div>
</template>

<script>
import { queryAdmission, queryByStudent, getAdmission } from "@/api";
import Loading from "@/components/Loading/index.vue";
export default {
  name: "Admission",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        name: "",
        region:
          JSON.parse(localStorage.getItem("province"))?.id || "10300000000007", // 省份
        firstMajor: "1000005",
        yearly: "", // 年份
        artWard: "", // 艺考方向
        comScore: "", // 综合分
        wishingId: "", // 志愿方案
      },
      wishingList: [],
      resultList: [],
      qflag: false,
      loading: false,
    };
  },
  mounted() {
    this.getWishingList();
    this.form.region =
      JSON.parse(localStorage.getItem("province"))?.id || "10300000000007";
    let userInfo = JSON.parse(localStorage.getItem("studentDetail"));
    this.form.yearly = userInfo.yearly_dictText;
    this.form.artWard = userInfo.artWard_dictText;
    this.form.comScore = userInfo.comScore;
  },
  methods: {
    async getWishingList() {
      let student = JSON.parse(localStorage.getItem("userList")).recordId;
      await queryByStudent(student).then((res) => {
        this.wishingList = res.data.reverse();
      });
    },
    admissionQuery() {
      if (this.form.wishingId === null || this.form.wishingId === "") {
        this.$notify({
          type: "warning",
          title: "提示",
          message: "请选择志愿方案",
          showClose: false,
        });
      } else {
        this.qflag = false;
        this.loading = true;
        let wishing = this.form.wishingId;
        let yearly = JSON.parse(localStorage.getItem("studentDetail"))?.yearly;
        let student = JSON.parse(
          localStorage.getItem("studentDetail")
        )?.recordId;
        getAdmission(student, wishing, yearly).then((res) => {
          if (res.code === 200) {
            this.resultList = res.data;
            this.loading = false;
            this.qflag = true;
          }
        });
        
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .noadssion {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-size: 22px;
    color: #2e0bb9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    text-transform: none;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-color: #ef5a2b;
    border-width: 1px; /* 边框宽度 */
    border-style: dashed; /* 边框样式 */
  }

  .table {
    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #ef5a2b;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #ef5a2b;
      background: #ef5a2b;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #ef5a2b;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #ef5a2b;
      background: #ef5a2b;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep {
      .el-select-dropdown__item.selected {
        color: #ef5a2b;
      }

      el-input.is-focus .el-input__inner {
        border-color: #ef5a2b;
      }
      .el-input__inner:focus {
        border-color: #ef5a2b;
      }
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #aeaeae;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    .form_item_right {
      display: flex;
      justify-content: right;

      .form_item_right_button {
        width: 300px;
      }
    }
  }

  .el_table {
    ::v-deep .el-table__header > .has-gutter {
      color: #373737;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>