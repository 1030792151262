<template>
  <div class="college-detail">
    <div class="card_group_title" v-if="examSchool.length > 0">
      <img src="@/assets/images/collegedetail/icon_title.png" />
      招生方向
    </div>
    <div class="list" v-for="item in examSchool" :key="item.group">
     <div v-if="item.group">  { {{ item.group }} } {{ item.collegeName }} </div>
      <div
        class="list-content"
        v-for="(item1, index1) in item.item"
        :key="index1"
      >
        <div class="list-content-left">
          <div class="list-title">{{ item1.batch }}</div>
          <div class="list-content-item">
            【{{ item1.majorCode }}】 {{ item1.major }}
          </div>
        </div>
        <div class="list-content-right">
          <div
            class="probability"
            :style="{
              color: item1.probability === null ? '#999' : '#ef5a2b',
              fontSize: item1.probability === null ? '16px' : '34px',
            }"
          >
            {{
              item1.probability === null ? "暂无概率" : item1.probability + "%"
            }}
          </div>
         <!--  <el-button
            class="addSchoolExam"
            v-if="item1.canCalculate == 1 && item1.probability == null"
            @click="addSchoolExam(item.collegeName, item1)"
            >+添加校考院校</el-button
          > -->

          <el-button
            class="addSchoolExam"
            @click="toDetail(item1.recordId)"
            >查看分析</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryAdmissionAnalysis } from "@/api";
export default {
  name: "schoolExam",
  data() {
    return {
     
      examSchool: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    toDetail(id) {
      let college = this.$route.query.id;
      window.open(`/examDetail?id=${id}&college=${college}`, "_blank");
    },
    getData() {
      //   console.log("college", this.$route.query.id);
      let college = this.$route.query.id;
      let student = JSON.parse(localStorage.getItem("studentDetail"))?.recordId;
      queryAdmissionAnalysis(college, student).then((res) => {
        this.examSchool = res.data;
      });
    },
    
  
  },
};
</script>
<style lang="scss" scoped>
.college-detail {
  .card_group_title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 20px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    // margin-bottom: 20px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
  .list {
    margin-top: 20px;
    .list-content {
      height: 60px;
      margin-top: 20px;
      background: #fff;
      padding: 20px;
      width: 1240px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list-content-left {
        width: 900px;
        .list-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 18px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 10px;
        }
        .list-content-item {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 18px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 10px;
        }
      }
      .list-content-right {
        width: 340px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addSchoolExam {
          border: 1px solid #ef5a2b;
          color: #ef5a2b;
        }
      }
    }
  }
  .showCard {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .el-form {
      width: 700px;
      height: auto;
      background: #fff;
      padding: 20px;
      .el-form-item {
        display: flex;
      }
      .form-title {
        font-size: 20px;
        font-weight: bold;

        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }
}
</style>



<!-- <template>
  <div class="content">
    <div class="major">
      <div class="major-top">
        [{{ dataList.majorCode }}] {{ dataList.major }}
      </div>
      <div class="major-middle">
        录取批次： <span>{{ dataList.batch }}</span> &nbsp;&nbsp;&nbsp;&nbsp;
        录取概率： <span>{{ dataList.probability }}%</span>
      </div>
      <div class="major-bottom">
        注：分析结果基于历年录取情况为数据依准，2025年信息更新后同步更新录取结果，仅供参考。
      </div>
    </div>
    <div class="pitching">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        考试院投档
      </div>
      <div class="pitching-item">
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">录&nbsp;取&nbsp;&nbsp;批&nbsp;次：{{ dataList.batch }}</span>
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">专业批次线：</span
          >{{ dataList.batchInfo?.majorLineScore }}分（<span style="color: red"
            >{{ dataList.batchInfo?.majorLineYear }}年</span
          >
          ）<span
            ><span
              :style="{
                color:
                dataList.batchInfo?.majorLineScore <= majorLineScore
                    ? 'green'
                    : '#ef5a2b',
              }"
              >{{  dataList.batchInfo?.majorLineScore <= majorLineScore? "达线" : "未达线" }}</span
            ></span
          >
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">文化批次线：</span
          >{{ dataList.batchInfo?.culturalLineScore }}分（<span style="color: red"
            >{{ dataList.batchInfo?.culturalLineYear }}年</span
          >
          ）<span><span
              :style="{
                color:
                dataList.batchInfo?.culturalLineScore <= culScore
                    ? 'green'
                    : '#ef5a2b',
              }"
              >{{  dataList.batchInfo?.culturalLineScore <= culScore? "达线" : "未达线" }}</span
            ></span
          ></span>
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">计&nbsp;&nbsp;&nbsp;&nbsp;划&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数：</span
          >{{ dataList.batchInfo?.planNum }}人（<span style="color: red"
            >{{ dataList.batchInfo?.planNumYear }}年</span
          >
          ）
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">投&nbsp;档&nbsp;&nbsp;比&nbsp;例：</span
          >{{ dataList.batchInfo?.proportion }}
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">投&nbsp;档&nbsp;&nbsp;规&nbsp;则：</span
          >{{ dataList.batchInfo?.rule }}（<span style="color: red"
            >{{ dataList.batchInfo?.ruleYear }}年</span
          >
          ）
        </div>
      </div>
    </div>
    <div class="pitching" v-if="dataList.majorEnrollRequire">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        专业招生要求
      </div>
     <div class="title">
      <div>限制条件</div>
      <div>要求</div>
      <div>达标状态</div>
     </div>
     <div class="body">
      <div>身体要求</div>
      <div>{{dataList.majorEnrollRequire.bodyRequire}}</div>
      <div></div>
     </div>
     <div class="scoreLine">
      <div>院校文化录取控制线</div>
      <div>{{dataList.majorEnrollRequire.collegeCulturalLine}}分</div>
      <div :style="{color:dataList.majorEnrollRequire.collegeCulturalLine>=culScore?'red':'green'}">{{dataList.majorEnrollRequire.collegeCulturalLine>=culScore?'未达线':'达线'}}</div>
     </div>
     <div class="subject">
      <div>选科</div>
      <div>{{dataList.majorEnrollRequire.optionalSubjectName}}</div>
      <div :style="{color:dataList.majorEnrollRequire.optionalSubject===subjectCode?'green':'red'}">{{dataList.majorEnrollRequire.optionalSubject===subjectCode?'达标':'未达标'}}</div>
     </div>
    </div>
    <div class="pitching" v-if="dataList.collegeAnnualInvestment">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        本校历年投档情况
      </div>
      <el-table :data="dataList.collegeAnnualInvestment">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="planNum" label="计划数" align="center">
          <template slot-scope="scope">
            {{ scope.row.planNum ? scope.row.planNum + '人' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minScore" label="录取最低分" align="center">
          <template slot-scope="scope">
            {{ scope.row.minScore ? scope.row.minScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minRank" label="最低位次" align="center"></el-table-column>
      </el-table>
      <div style="margin-left: 50px;margin-top: 30px;color: #a3a2a2;"> 投档公式：  <span style="color: #000;"> 文化分*0.5+统考专业分*2.5*0.5   <span style="color: red;">(您的投档分:{{ dataList.unifiedExamScore }})</span></span></div>
    </div>
    <div class="pitching" v-if="dataList.majorAnnualAdmission">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        本专业历年录取情况
      </div>
      <el-table :data="dataList.majorAnnualAdmission">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="planNum" label="计划数" align="center">
          <template slot-scope="scope">
            {{ scope.row.planNum ? scope.row.planNum + '人' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minScore" label="录取最低分" align="center">
          <template slot-scope="scope">
            {{ scope.row.minScore ? scope.row.minScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="cultureScore" label="所需文化分" align="center">
            <template slot-scope="scope">
                {{ scope.row.cultureScore ? scope.row.cultureScore + '分' : "-" }}
            </template>
        </el-table-column>
      </el-table>
      <div style="margin-left: 50px;margin-top: 30px;color: #a3a2a2;"> 投档公式：  <span style="color: #000;"> 文化分*0.5+统考专业分/300*750*0.5   <span style="color: red;">(您的分数:{{ dataList.unifiedExamScore }})</span></span></div>
    </div>
    <div class="pitching">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        填报分析法
      </div>
      <div class="tabs">
        <div :class="index == 1? 'tab checked':'tab'" @click="index = 1">线差等效法</div>
        <div :class="index == 2? 'tab checked':'tab'" @click="index = 2">线差法</div>
      </div>
      <div class="tabsItem">
        <div class="Item"  v-if="index == 1">
          <el-table :data="dataList.lineDeviationEqualInfo">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="admissionScore" label="录取分" align="center">
          <template slot-scope="scope">
            {{ scope.row.admissionScore ? scope.row.admissionScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="equalScore" label="您的等效分" align="center">
          <template slot-scope="scope">
            {{ scope.row.equalScore ? scope.row.equalScore + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
        </div>
        <div class="Item" v-if="index == 2">
          <el-table style="margin-bottom: 50px;" :data="dataList.lineDeviationInfo.top">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="culturalLine" label="文化控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.culturalLine ? scope.row.culturalLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="majorLine" label="专业控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.majorLine ? scope.row.majorLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="comLine" label="综合控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.comLine ? scope.row.comLine + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
          <el-table :data="dataList.lineDeviationInfo.bottom">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="comLine" label="综合控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.comLine ? scope.row.comLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="lineDeviation" label="线差" align="center">
          <template slot-scope="scope">
            {{ scope.row.lineDeviation ? scope.row.lineDeviation + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="equalScore" label="您的等效分" align="center">
          <template slot-scope="scope">
            {{ scope.row.equalScore ? scope.row.equalScore + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="tips" style="line-height: 1.5">
          <span>注：</span><br /><span
            >1、考生线差 = 最新年考生综合分 - 最新年综合控线 某年等效分 =
            某年综合控线 + 考生线差</span
          ><br /><span
            >2、教育部近年要求逐步提高艺术类各专业文化控线，导致大部分省艺术类文化控线占普通文化线比例每年提升，为了让文化控线相对合理，提升数据参考度，优化了文化控线的计算逻辑：
            某年文化控线 = 某年普通文化线X最新年艺术文化占普通文化线比例</span
          ><br /><span
            >3、同等情况下，位次法参考准确度高于线差法。如果院校的录取公式和省份的投档公式相同，建议参考位次法</span
          ><br />
    </div>

    <div class="pitching">
        <div style="margin-top: 20px;text-align: center;">综上所述，得以结论</div>
        <div style="margin-top: 20px;text-align: center;line-height: 1;">本校的录取概率为<span style="color:red;font-size: 30px;margin-left: 10px;">{{dataList.probability}}%</span></div>
        <div @click="show = true" style="margin-top: 20px;margin-bottom: 20px ;cursor: pointer;text-align: center;color:#ef5a2b">录取概率是怎么计算的?</div>
    </div>
    <div class="showCard" @click="show = false" v-if="show">
        <div class="card">
            <img  src="@/assets/images/collegedetail/tips.png" alt="">
        </div>       
    </div>
    
  </div>
</template>
<script>
import { queryAdmissionAnalysisDetail } from "@/api";
export default {
  name: "index",
  data() {
    return {
      dataList: {},
      majorLineScore: 0,
      culScore: 0,
      subjectCode: 0,
      index: 1,
      show: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.majorLineScore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.majorScore;
      this.culScore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.cultureScore;
      this.subjectCode = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.firstMajor;
      let collegeId = this.$route.query.id;
      let studentId = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.recordId;
      queryAdmissionAnalysisDetail(collegeId, studentId).then((res) => {
        this.dataList = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1280px;
  .showCard {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .card {
      width: 800px;
      height: 1000px;
      overflow: hidden;
      overflow-y: auto;
      img {
        width: 100%;
      }
    }
  }
  .pitching {
    margin-top: 30px;
    background: #fff;
    padding: 20px;
    width: 1240px;
    .card_group_title {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 500;
      font-size: 20px;
      color: #242424;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;

      img {
        margin-right: 10px;
      }

      .card_group_title_right {
        position: absolute;
        right: 10px;
        color: #ef5a2b;
        font-family: Source Han Sans SC, Source Han Sans SC;
      }
    }
  }
  .pitching {
    .card_group_content_text {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      margin-bottom: 10px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 20px;
    }
    .title {
      color: #ababab;
    }
    .title,
    .body,
    .scoreLine,
    .subject {
      font-family: Source Han Sans SC, Source Han Sans SC;
      display: flex;
      margin-bottom: 10px;
      &:hover {
        color: #ef5a2b;
      }
      div {
        width: 33%;
        text-align: center;
      }
    }
    .tabs {
      width: 100%;
      height: 50px;
      display: flex;
      .tab {
        width: 50%;
        height: 100%;
        text-align: center;
        line-height: 50px;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
      }
      .checked {
        color: #ef5a2b;
      }
    }
  }
  .tips {
    width: 1280px;
    margin-top: 30px;
    span {
      line-height: 2;
    }
  }
  .major {
    background: #fff;
    padding: 20px;
    .major-top,
    .major-middle,
    .major-bottom {
      margin-bottom: 10px;
    }
    .major-top {
      font-size: 26px;
    }
    .major-middle {
      color: #ababab;
      span {
        color: #000;
        &:last-child {
          color: #ef5a2b;
        }
      }
    }
    .major-bottom {
      color: #ababab;
    }
  }
}
</style> -->