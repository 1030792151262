<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    // window.addEventListener('beforeunload', this.close)
  },
  methods: {
    close(event) {
      // console.log('1111111111',event);
      localStorage.clear();
      event.preventDefault(); // 如果需要阻止默认行为（例如显示默认的警告信息）
      event.returnValue = ''; // Chrome 需要这个属性来显示自定义的警告信息
    },
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
#app {
  // max-width:1920px;
  min-width: 1500px;
  min-height: 10vh;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
</style>
