<template>
  <div class="content">
    <div class="desc">
      <div class="desc-top">
        <div class="desc-top-left">
          <img :src="schoolDesc.collegeLogo" alt="" />
        </div>
        <div class="desc-top-right">
          <div class="desc-top-right-top">
            {{ schoolDesc.collegeName }}
            <span>{{ schoolDesc.province_dictText }}</span
            ><span>{{ schoolDesc.cityArea }}</span
            ><span v-if="details.group">[{{ details.group }}]专业组</span
            ><span>[{{ schoolDesc.collegeCode }}]院校代码</span>
          </div>
          <div class="desc-top-right-bottom">
            <span v-for="(item, index) in schoolDesc.tagNames" :key="index">{{
              item
            }}</span>
          </div>
        </div>
      </div>
      <div class="desc-bottom">
        <div>
          <span>招生专业</span>
          <div>{ {{ details.majorCode }} } {{ details.major }}</div>
        </div>
        <div>
          <span>录取批次</span>
          <div>{{ details.batch }}</div>
        </div>
        <div>
          <span>录取概率</span>
          <div style="color: red">
            {{ details.probability ? details.probability : "--" }}%
          </div>
        </div>
      </div>
    </div>
    <div class="pitching">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        考试院投档
      </div>
      <div class="pitching-item">
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">录取批次：{{ details.batch }}</span>
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">专业批次线：</span
          >{{ details.batchInfo?.majorLineScore }}分（<span style="color: red"
            >{{ details.batchInfo?.majorLineYear }}年</span
          >
          ）<span
            ><span
              :style="{
                color:
                  details.batchInfo?.majorLineScore <= majorscore
                    ? 'green'
                    : '#ef5a2b',
              }"
              >{{  details.batchInfo?.majorLineScore <= majorscore? "达线" : "未达线" }}</span
            ></span
          >
        </div>
        <div class="card_group_content_text">
          <span style="color: #a3a2a2">文化批次线：</span
          >{{ details.batchInfo?.culturalLineScore }}分（<span style="color: red"
            >{{ details.batchInfo?.culturalLineYear }}年</span
          >
          ）<span><span
              :style="{
                color:
                  details.batchInfo?.culturalLineScore <= culScore
                    ? 'green'
                    : '#ef5a2b',
              }"
              >{{  details.batchInfo?.culturalLineScore <= culScore? "达线" : "未达线" }}</span
            ></span
          ></span>
        </div>
      </div>
    </div>
    <div class="pitching" v-if="details.enrollRequire">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        专业常规招生要求
      </div>
     <div class="title">
      <div>限制条件</div>
      <div>要求</div>
      <div>达标状态</div>
     </div>
     <div class="body">
      <div>身体要求</div>
      <div>{{details.enrollRequire.bodyRequire}}</div>
      <div></div>
     </div>
     <div class="scoreLine">
      <div>院校文化录取控制线</div>
      <div>{{details.enrollRequire.collegeCulturalLine}}分</div>
      <div :style="{color:details.enrollRequire.collegeCulturalLine>=culScore?'red':'green'}">{{details.enrollRequire.collegeCulturalLine>=culScore?'未达线':'达线'}}</div>
     </div>
     <div class="subject">
      <div>选科</div>
      <div>{{details.enrollRequire.optionalSubjectName}}</div>
      <div :style="{color:details.enrollRequire.optionalSubject===subjectCode?'green':'red'}">{{details.enrollRequire.optionalSubject===subjectCode?'达标':'未达标'}}</div>
     </div>
    </div>
    <div class="pitching" v-if="details.annualAdmissionStatus">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        本专业常规历年录取情况
      </div>
      <el-table :data="details.annualAdmissionStatus">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="planNum" label="计划数" align="center">
          <template slot-scope="scope">
            {{ scope.row.planNum ? scope.row.planNum + '人' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minScore" label="录取最低分" align="center">
          <template slot-scope="scope">
            {{ scope.row.minScore ? scope.row.minScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minRank" label="最低位次" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="pitching" v-if="details.exceptiveEnrollRequire">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        专业破格招生情况
      </div>
      <div class="title">
      <div>限制条件</div>
      <div>要求</div>
      <div>达标状态</div>
     </div>
     <div class="body">
      <div>录取要求</div>
      <div>{{details.exceptiveEnrollRequire.admissionRequire}}</div>
      
     </div>
     <div class="body">
      <div>身体要求</div>
      <div>{{details.exceptiveEnrollRequire.bodyRequire}}</div>
      <div></div>
     </div>
     <div class="scoreLine">
      <div>院校文化录取控制线</div>
      <div>{{details.exceptiveEnrollRequire.collegeCulturalLine?details.exceptiveEnrollRequire.collegeCulturalLine:'-'}}分</div>
      <div :style="{color:details.exceptiveEnrollRequire.collegeCulturalLine>=culScore?'red':'green'}">{{details.exceptiveEnrollRequire.collegeCulturalLine>=culScore?'未达线':'达线'}}</div>
     </div>
     <div class="subject">
      <div>选科</div>
      <div>{{details.exceptiveEnrollRequire.optionalSubjectName?details.exceptiveEnrollRequire.optionalSubjectName:'无要求'}}</div>
      <div :style="{color:details.exceptiveEnrollRequire.optionalSubject===subjectCode?'green':'red'}">{{details.exceptiveEnrollRequire.optionalSubject===subjectCode?'达标':'未达标'}}</div>
     </div>
    </div>
    <div class="pitching" v-if="details.exceptiveAnnualAdmission">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        本专业历年破格录取情况
      </div>
      <el-table :data="details.exceptiveAnnualAdmission">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="planNum" label="计划数" align="center">
          <template slot-scope="scope">
            {{ scope.row.planNum ? scope.row.planNum + '人' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minScore" label="录取最低分" align="center">
          <template slot-scope="scope">
            {{ scope.row.minScore ? scope.row.minScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minRank" label="最低位次" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="pitching" v-if="details.majorEnrollRequire">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        专业招生要求
      </div>
      <div class="title">
      <div>限制条件</div>
      <div>要求</div>
      <div>达标状态</div>
     </div>
     <div class="body" v-if="details.majorEnrollRequire?.admissionRequire">
      <div>录取要求</div>
      <div>{{details.majorEnrollRequire.admissionRequire}}</div>
      
     </div>
     <div class="body">
      <div>身体要求</div>
      <div>{{details.majorEnrollRequire.bodyRequire}}</div>
      <div></div>
     </div>
     <div class="scoreLine">
      <div>院校文化录取控制线</div>
      <div>{{details.majorEnrollRequire.collegeCulturalLine?details.majorEnrollRequire.collegeCulturalLine:'-'}}分</div>
      <div :style="{color:details.majorEnrollRequire.collegeCulturalLine>=culScore?'red':'green'}">{{details.majorEnrollRequire.collegeCulturalLine>=culScore?'未达线':'达线'}}</div>
     </div>
     <div class="subject">
      <div>选科</div>
      <div>{{details.majorEnrollRequire.optionalSubjectName?details.majorEnrollRequire.optionalSubjectName:'无要求'}}</div>
      <div :style="{color:details.majorEnrollRequire.optionalSubject===subjectCode?'green':details.majorEnrollRequire.optionalSubject===null?'green':'red'}">{{details.majorEnrollRequire.optionalSubject===subjectCode?'达标':details.majorEnrollRequire.optionalSubject=== null?'达标':'未达标'}}</div>
     </div>
     <div
        class="subject"
        v-if="details.majorEnrollRequire?.majorScoreRequire"
      >
        <div>专业分数要求</div>
        <div>
          {{
            details.majorEnrollRequire.majorScoreRequire
              ? details.majorEnrollRequire.majorScoreRequire
              : "无"
          }}
        </div>
        <div
          :style="{
            color:
              details.majorEnrollRequire.majorScoreRequire <= majorscore
                ? 'green'
                : details.majorEnrollRequire.majorScoreRequire === null
                ? 'green'
                : 'red',
          }"
        >
          {{
            details.majorEnrollRequire.majorScoreRequire <= majorscore
              ? "达标"
              : details.majorEnrollRequire.majorScoreRequire === null
              ? "达标"
              : "未达标"
          }}
        </div>
      </div>
    </div>
    <div class="pitching" v-if="details.majorAnnualAdmissionStatus">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        本专业历年录取情况
      </div>
      <el-table :data="details.majorAnnualAdmissionStatus">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="planNum" label="计划数" align="center">
          <template slot-scope="scope">
            {{ scope.row.planNum ? scope.row.planNum + '人' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minScore" label="录取最低分" align="center">
          <template slot-scope="scope">
            {{ scope.row.minScore ? scope.row.minScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="minRank" label="最低位次" align="center">
          <template slot-scope="scope">
            {{ scope.row.minRank ? scope.row.minRank: "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="cultureScore" label="文化分" align="center">
          <template slot-scope="scope">
            {{ scope.row.cultureScore ? scope.row.cultureScore + '分' : "-" }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pitching" v-if="details.lineDeviationEqualInfo">
      <div class="card_group_title">
        <img src="@/assets/images/collegedetail/icon_title.png" />
        填报分析法
      </div>
      <div class="tabs">
        <div :class="index == 1? 'tab checked':'tab'" @click="index = 1">线差等效法</div>
        <div :class="index == 2? 'tab checked':'tab'" @click="index = 2">线差法</div>
      </div>
      <div class="tabsItem">
        <div class="Item"  v-if="index == 1">
          <el-table :data="details.lineDeviationEqualInfo">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="admissionScore" label="录取分" align="center">
          <template slot-scope="scope">
            {{ scope.row.admissionScore ? scope.row.admissionScore + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="equalScore" label="您的等效分" align="center">
          <template slot-scope="scope">
            {{ scope.row.equalScore ? scope.row.equalScore + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
        </div>
        <div class="Item" v-if="index == 2">
          <el-table style="margin-bottom: 50px;" :data="details.lineDeviationInfo?.top">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="culturalLine" label="文化控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.culturalLine ? scope.row.culturalLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="majorLine" label="专业控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.majorLine ? scope.row.majorLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="comLine" label="综合控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.comLine ? scope.row.comLine + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
          <el-table :data="details.lineDeviationInfo?.bottom">
        <el-table-column prop="year" label="年份" align="center"></el-table-column>
        <el-table-column prop="comLine" label="综合控线" align="center">
          <template slot-scope="scope">
            {{ scope.row.comLine ? scope.row.comLine + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="lineDeviation" label="线差" align="center">
          <template slot-scope="scope">
            {{ scope.row.lineDeviation ? scope.row.lineDeviation + '分' : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="equalScore" label="您的等效分" align="center">
          <template slot-scope="scope">
            {{ scope.row.equalScore ? scope.row.equalScore + '分' : "-" }}
          </template>
        </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="tips" style="line-height: 1.5">
          <span>注：</span><br /><span
            >1、考生线差 = 最新年考生综合分 - 最新年综合控线 某年等效分 =
            某年综合控线 + 考生线差</span
          ><br /><span
            >2、教育部近年要求逐步提高艺术类各专业文化控线，导致大部分省艺术类文化控线占普通文化线比例每年提升，为了让文化控线相对合理，提升数据参考度，优化了文化控线的计算逻辑：
            某年文化控线 = 某年普通文化线X最新年艺术文化占普通文化线比例</span
          ><br /><span
            >3、同等情况下，位次法参考准确度高于线差法。如果院校的录取公式和省份的投档公式相同，建议参考位次法</span
          ><br />
    </div>
    <div class="pitching">
        <div style="margin-top: 20px;text-align: center;">综上所述，得以结论</div>
        <div style="margin-top: 20px;text-align: center;line-height: 1;">本校的录取概率为<span style="color:red;font-size: 30px;margin-left: 10px;">{{details.probability}}%</span></div>
        <div @click="show = true" style="margin-top: 20px;margin-bottom: 20px ;cursor: pointer;text-align: center;color:#ef5a2b">录取概率是怎么计算的?</div>
    </div>
    <div class="showCard" @click="show = false" v-if="show">
        <div class="card">
            <img  src="@/assets/images/collegedetail/tips.png" alt="">
        </div>       
    </div>
  </div>
</template>
<script>
import { queryEnrollmentDirectionDetail, queryById2, subject } from "@/api";
export default {
  data() {
    return {
      schoolDesc: {},
      details: {},
      culScore: 0,
      majorscore: 0,
      subjectCode: 0,
      index: 1,
      show:false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.culScore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.cultureScore;
      this.majorscore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.majorScore;
      this.subjectCode = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.firstMajor;
      let studentId = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.recordId;
      let enrollDirectionId = this.$route.query.id;
      queryEnrollmentDirectionDetail(enrollDirectionId, studentId).then(
        (res) => {
          console.log(res);
          this.details = res.data;
        }
      );
      queryById2(this.$route.query.college).then((res) => {
        console.log(res);
        this.schoolDesc = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1280px;
  //   height: 500px;
  margin: 0 auto;
   .showCard {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .card {
      width: 800px;
      height: 1000px;
      overflow: hidden;
      overflow-y: auto;
      img {
        width: 100%;
      }
    }
  }
  .desc {
    width: 1240px;
    padding: 20px;
    background: #fff;
    margin-top: 30px;
    .desc-top {
      width: 1240px;
      display: flex;
      align-items: center;
      .desc-top-left {
        width: 135px;
        height: 135px;
        background: #fff;
        margin-right: 30px;
        img{
          width: 135px;
        height: 135px;
        }
      }
      .desc-top-right {
        .desc-top-right-top {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          span {
            font-size: 18px;
            font-weight: 500;
            margin-left: 30px;
          }
        }
        .desc-top-right-bottom {
          span {
            color: #ababab;
            margin-right: 10px;
          }
        }
      }
    }
    .desc-bottom {
      border-top: 1px solid #ebebeb;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;
      div {
        display: flex;
        span {
          color: #ababab;
          margin-right: 30px;
        }
      }
    }
  }
  .pitching {
    margin-top: 30px;
    background: #fff;
    padding: 20px;
    width: 1240px;
    .card_group_title {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 500;
      font-size: 20px;
      color: #242424;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;

      img {
        margin-right: 10px;
      }

      .card_group_title_right {
        position: absolute;
        right: 10px;
        color: #ef5a2b;
        font-family: Source Han Sans SC, Source Han Sans SC;
      }
    }
  }
  .pitching {
    .card_group_content_text {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      margin-bottom: 10px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 20px;
    }
    .title {
      color: #ababab;
    }
    .title,
    .body,
    .scoreLine,
    .subject {
      font-family: Source Han Sans SC, Source Han Sans SC;
      display: flex;
      margin-bottom: 10px;
      &:hover {
        color: #ef5a2b;
      }
      div {
        width: 33%;
        text-align: center;
        
      }
    }
    .tabs {
      width: 100%;
      height: 50px;
      display: flex;
      .tab {
        width: 50%;
        height: 100%;
        text-align: center;
        line-height: 50px;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
      }
      .checked {
        color: #ef5a2b;
      }
    }
  }
  .tips {
        width: 1280px;
        margin-top: 30px;
        span {
          line-height: 2;
        }
      }
}
</style>