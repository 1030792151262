<template>
  <div class="arithmetic">
    <div class="title">查询位次</div>
    <div class="table">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="省份">
              <el-select
                v-model="form.region"
                placeholder="请选择省份"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in regionSel"
                  :key="item.recordId"
                  :label="item.abbrName"
                  :value="item.recordId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="位次类别">
              <el-select
                v-model="form.category"
                placeholder="请选择位次类别"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in positionCategory"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" v-if="form.category == '1'">
          <el-col :span="12">
            <el-form-item label="首选科目">
              <el-select
                v-model="form.firstMajor"
                placeholder="请选择首选科目"
                :popper-append-to-body="false"
              >
                <el-option label="物理" value="1000004"></el-option>
                <el-option label="历史" value="1000005"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="高考分数">
              <el-input v-model="form.culturalScore" @blur="verify(1)">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" v-if="form.category == '2'">
          <el-col :span="12">
            <el-form-item label="专业类别">
              <el-cascader
                v-model="form.profession"
                :options="professionSel"
                :props="{
                  expandTrigger: 'hover',
                  value: 'recordId',
                  label: 'artName',
                  children: 'children',
                  checkStrictly: false,
                  emitPath: true,
                }"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业分数">
              <el-input v-model="form.artScore" @blur="verify(1)">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="rankSelect"
          >查&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
        >
      </el-form>
    </div>

    <!-- <div class="title">历年位次</div> -->
    <div class="el_table">
      <el-table :data="resultList" style="width: 100%">
        <el-table-column
          prop="year"
          label="年份"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="percentage"
          label="超越全省考生的比例"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.percentage ? `${scope.row.percentage}` : "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="rank"
          label="最低排名"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.rank || "--" }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { rankSelect, artexam, province, artRankSelect } from "@/api";

export default {
  name: "RankLookup",
  data() {
    return {
      form: {
        name: "",
        region:
          JSON.parse(localStorage.getItem("province"))?.id || "10300000000007", // 省份
        firstMajor: "1000005",
        culturalScore: 750, //文化分
        artScore: 300, //专业分
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        category: "",
      },
      positionCategory: [
        { text: "文化位次", value: "1" },
        { text: "专业位次", value: "2" },
      ],
      regionSel: [],
      professionSel: [],
      resultList: [],
    };
  },
  mounted() {
    this.getRegion();
    this.getartexam();
    this.form.region =
      JSON.parse(localStorage.getItem("province"))?.id || "10300000000007";
  },
  methods: {
    verify(data) {
      if (data === 1) {
        if (this.form.culturalScore < 0 || this.form.culturalScore > 750) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "请输入0-750分之间成绩",
            showClose: false,
          });
          this.form.culturalScore = 750;
        }
      }
    },
    arithmeticForm() {
      let flag = false;
      for (let i in this.form) {
        if (this.form[i] === null || this.form[i] === "") {
          flag = true;
        }
      }
      if (flag) {
        this.$notify({
          type: "warning",
          title: "提示",
          message: "请补充信息",
          showClose: false,
        });
      }
      this.getArithmeticFile();
    },
    getRegion() {
      province().then((res) => {
        if (res.code === 200) {
          this.regionSel = res.data?.reverse();
        }
      });
    },
    getartexam() {
      let province = this.form.region;
      artexam(province).then((res) => {
        if (res.code === 200) {
          this.professionSel = res.data;
          console.log("res=-===", this.professionSel);
        }
      });
    },
    rankSelect() {
      if (this.form.category == 1) {
        let province = this.form.region;
        let firstMajor = this.form.firstMajor;
        let cultureScore = this.form.culturalScore;
        rankSelect(province, firstMajor, cultureScore).then((res) => {
          if (res.code === 200) {
            this.resultList = res.data;
          }
        });
      } else if (this.form.category == 2) {
        let province = this.form.region;
        let score = this.form.artScore;
        let artWard = this.form.profession[0];
        let subWard = this.form.profession[1] || "";
        artRankSelect(province,artWard,subWard,score).then((res) => {
        if (res.code === 200) {
          this.resultList = res.data;
        }
      });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .table {
    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #ef5a2b;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #ef5a2b;
      background: #ef5a2b;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #aeaeae;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #ef5a2b;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #ef5a2b;
      background: #ef5a2b;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep {
      .el-select-dropdown__item.selected {
        color: #ef5a2b;
      }

      el-input.is-focus .el-input__inner {
        border-color: #ef5a2b;
      }
      .el-input__inner:focus {
        border-color: #ef5a2b;
      }
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #aeaeae;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    .form_item_right {
      display: flex;
      justify-content: right;

      .form_item_right_button {
        width: 300px;
      }
    }
  }

  .el_table {
    ::v-deep .el-table__header > .has-gutter {
      color: #373737;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
