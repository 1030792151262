<template>
  <div class="collegedetail">
    <div class="title">
      <div class="img">
        <img :src="detailList.collegeLogo" alt="" />
      </div>
      <div class="main">
        <div class="name">{{ detailList.collegeName }}</div>
        <div class="label">
          <span v-for="(item, index) in detailList.tagNames" :key="index">{{
            item
          }}</span>
        </div>
        <div class="info">
          <span>{{ detailList.province_dictText }}</span
          ><span>{{ detailList.eduLevel_dictText }}</span
          ><span>{{ detailList.natureType_dictText }}</span>
        </div>
      </div>
      <div class="add" @click="addSchool(detailList.recordId)">
        <img
          :src="
            isConcern
              ? require('../../../assets/images/collegedetail/ing.png')
              : require('../../../assets/images/collegedetail/add.png')
          "
          alt=""
        />
        {{ isConcern ? "已关注" : "关注" }}
      </div>
    </div>
    <div class="main">
      <div class="top">
        <div class="tab">
          <div :class="mainIndex == 1 ? 'label' : 'label1'" @click="tab(1)">
            基本介绍
          </div>
          <div :class="mainIndex == 2 ? 'label' : 'label1'" @click="tab(2)">
            招录信息
          </div>
          <div :class="mainIndex == 3 ? 'label' : 'label1'" @click="tab(3)">
            招生数据
          </div>
          <div :class="mainIndex == 4 ? 'label' : 'label1'" @click="tab(4)">
            录取分析
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div v-if="mainIndex == 1" class="content">
        <div class="left">
          <div class="briefIntroduction">
            <el-carousel
              class="carousel"
              trigger="click"
              height="240px"
              style="width: 380px; border-radius: 10px"
            >
              <el-carousel-item v-for="item in imgList" :key="item.collegeImg">
                <h3 class="small">
                  <img
                    :src="item.collegeImg"
                    style="width: 100%; height: auto"
                    alt=""
                  />
                </h3>
              </el-carousel-item>
            </el-carousel>
            <div class="labs">
              <div
                class="leb"
                :title="'创建时间：' + this.detailList.establishYear"
              >
                <img
                  src="../../../assets/images/collegedetail/1.png"
                  alt=""
                /><span
                  >创建时间：{{
                    this.detailList.establishYear
                      ? this.detailList.establishYear
                      : "——"
                  }}</span
                >
              </div>
              <div
                class="leb"
                :title="'学校性质：' + detailList.natureType_dictText"
              >
                <img src="../../../assets/images/collegedetail/2.png" alt="" />
                <span>学校性质：{{ detailList.natureType_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'学历层次：' + detailList.eduLevel_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/3.png"
                  alt=""
                /><span>学历层次：{{ detailList.eduLevel_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'院校类型：' + detailList.collegeClasz_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/4.png"
                  alt=""
                /><span>院校类型：{{ detailList.collegeClasz_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="
                  '所在地：' +
                  detailList.province_dictText +
                  detailList.cityArea
                "
              >
                <img
                  src="../../../assets/images/collegedetail/5.png"
                  alt=""
                /><span
                  >所在地：{{ detailList.province_dictText
                  }}{{ detailList.cityArea }}</span
                >
              </div>
              <div class="leb" :title="'主管部门：' + detailList.belongTo">
                <img
                  src="../../../assets/images/collegedetail/6.png"
                  alt=""
                /><span>主管部门：{{ detailList.belongTo }}</span>
              </div>
              <div class="leb1">
                <div class="lab1-left">
                  <div class="img">硕</div>
                  硕士点
                </div>
                <div class="lab1-right">
                  {{ detailList.masterFst + detailList.masterSec }} 个
                </div>
              </div>
              <div class="leb1">
                <div class="lab1-left">
                  <div class="img">博</div>
                  博士点：
                </div>
                <div class="lab1-right">
                  {{ detailList.doctorFst + detailList.doctorSec }} 个
                </div>
              </div>
            </div>
          </div>
          <div class="card_img">
            <div class="card_group_title" v-if="imgList.length > 0">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              院校风采
            </div>
            <div class="left-main">
              <div
                v-html="
                  detailList.introduce ? detailList.introduce : '暂无内容'
                "
              ></div>
            </div>
          </div>

          <!--  
            <div class="cardInfo">
              <div class="wangzhi">官方网址：<span @click="toWeb(detailList.website)">{{detailList.website}}</span></div>
              <div class="wangzhi">电话咨询：{{detailList.recruitPhone}}</div>
              <div class="wangzhi">校区地址：{{detailList.address}}</div>
            </div>
          </div> -->
        </div>
        <div class="artList" v-if="majorList.length > 0">
          <div class="card_group_title">
            <img src="@/assets/images/collegedetail/icon_title.png" />
            艺术专业
          </div>
          <div
            class="artList-content"
            v-for="(item, index) in majorList"
            :key="index"
          >
            <div class="artList-content-title">
              {{ item.majorLevel }}级学科专业
            </div>
            <div
              v-for="item1 in item.item"
              :key="item1.majorId"
              class="artList-content-item"
            >
              <div class="top1">
                {{ item1.collegeMajorName }}
                <span v-if="item1.eduLevelName">{{ item1.eduLevelName }}</span>
                <span v-for="item2 in item1.featureTags" :key="item2">{{
                  item2
                }}</span>
                <span v-if="item1.isImportant === 1">院校重点学科</span>
                <span v-if="item1.nationFeature === 1">国家特色专业</span>
                <span v-if="item1.provinceFeature === 1">省特色学科</span>
              </div>
              <div class="artList-content-item-bottom">
                <div>学制：{{ item1.eduYear }}</div>
                <div>学费：{{ item1.eduFee ? item1.eduFee : 0 }}</div>
                <div v-if="item1.bodyRequire">
                  身体条件：{{ item1.bodyRequire }}
                </div>
              </div>
              <div
                class="artList-content-item-detail"
                v-if="showDetail == item1.recordId"
                v-html="item1.majorDetail"
              ></div>
              <div
                class="artList-content-item-unfold"
                v-if="item1.majorDetail"
                @click="unfold(item1.recordId)"
              >
                {{ showDetail == item1.recordId ? "收起" : "展开" }}
              </div>
            </div>
          </div>
        </div>
        <div class="score">
          <div class="card_group_title">
            <img src="@/assets/images/collegedetail/icon_title.png" />
            院校满意度
          </div>
          <div class="score-content">
            <div class="box">
              综合满意度：
              <el-rate
                :value="detailList.overallSatisfaction"
                disabled
                show-score
                text-color="#ff9900"
                score-template=""
              >
              </el-rate>
            </div>
            <div class="box">
              环境满意度：
              <el-rate
                :value="detailList.envirSatisfaction"
                disabled
                show-score
                text-color="#ff9900"
                score-template=""
              >
              </el-rate>
            </div>
            <div class="box">
              生活满意度：
              <el-rate
                :value="detailList.lifeSatisfaction"
                disabled
                show-score
                text-color="#ff9900"
                score-template=""
              >
              </el-rate>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="card_group_title">
            <img src="@/assets/images/collegedetail/icon_title.png" />
            官方信息
          </div>
          <div class="info">
            <span @click="toWeb(detailList.recruitUrl)"
              >官方网址：{{ detailList.recruitUrl }}</span
            >
            <span>电话咨询：{{ detailList.recruitPhone }}</span>
            <span>校区地址：{{ detailList.address }}</span>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 2" class="content">
        <div class="left">
          <div class="card_group">
            <div v-if="schoolexamInfo.length !== 0">
              <div class="card_group_title">
                <img src="@/assets/images/collegedetail/icon_title.png" />
                校考信息
                <div class="card_group_title_right" @click="collClick(1)">
                  {{ collationExaminationStu ? "收起" : "展开" }}
                </div>
              </div>

              <div
                class="card_group_content"
                :style="{ height: collationExaminationStu ? 'auto' : '300px' }"
                v-html="schoolexamInfo"
              ></div>

              <el-divider></el-divider>
            </div>
            <div v-if="examSubject !== null && examSubject !== ''">
              <div class="card_group_title">
                <img src="@/assets/images/collegedetail/icon_title.png" />
                考试科目
                <div class="card_group_title_right" @click="collClick(2)">
                  {{ collationExaminationStu1 ? "收起" : "展开" }}
                </div>
              </div>

              <div
                class="card_group_content"
                :style="{ height: collationExaminationStu1 ? 'auto' : '300px' }"
                v-html="examSubject"
              ></div>

              <el-divider></el-divider>
            </div>
            <div class="card_group_title" :style="{ margin: 0 }">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              招生信息
            </div>
            <div
              class="card_group_content"
              v-for="item in enrollList"
              :key="item.recordId"
              @click="detailClick(item.recordId)"
            >
              <div class="card_group_content_link">
                <div class="card_group_content_link_title">
                  {{ item.title }}
                </div>
                <div class="card_group_content_link_time">
                  {{ item.infoDate }}
                </div>
                <div class="card_group_content_link_right">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 3" class="content">
        <div class="left">
          <div class="card_group">
            <div class="card_group_title" v-if="echartsData1.length > 0">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              近5年招生计划数
            </div>
            <div class="chart-container" v-if="echartsData1.length > 0">
              <MercuryChart :options="options1" />
            </div>

            <el-divider></el-divider>

            <div class="card_group_title" v-if="echartsData2.length > 0">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              近5年校考报名人数
            </div>

            <div class="chart-container" v-if="echartsData2.length > 0">
              <MercuryChart :options="options2" />
            </div>

            <div class="card_group_title" style="margin-top: 30px">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              专业录取信息
            </div>
            <div class="table">
              <el-table :data="tableData1" style="width: 100%">
                <el-table-column
                  :filters="tableFilters"
                  :filter-method="filterHandler"
                  ref="filterTable"
                  column-key="date"
                  :prop="'yearly.text' ? 'yearly.text' : '-'"
                  label="年份"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.yearly.text ? scope.row.yearly.text : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :prop="'major' ? 'major' : '-'"
                  label="专业"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.major ? scope.row.major : "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="controlLine"
                  label="控线"
                  width="130"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.controlLine ? scope.row.controlLine : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="examRequire"
                  label="校统考要求"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.examRequire ? scope.row.examRequire : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="admissionMinRank"
                  label="最低录取位"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.admissionMinRank
                        ? scope.row.admissionMinRank
                        : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="admissionMinScore"
                  label="最低录取分"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.admissionMinScore
                        ? scope.row.admissionMinScore
                        : "-"
                    }}</span>
                  </template> </el-table-column
                ><el-table-column
                  prop="collegeYear"
                  label="学制"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.collegeYear ? scope.row.collegeYear : "-"
                    }}</span>
                  </template> </el-table-column
                ><el-table-column
                  prop="collegeFree"
                  label="学费"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.collegeFree ? scope.row.collegeFree : "-"
                    }}</span>
                  </template> </el-table-column
                ><el-table-column
                  :prop="'planNum' ? 'planNum' : '-'"
                  label="计划数"
                  align="center"
                  ><template slot-scope="scope">
                    <span>{{
                      scope.row.planNum ? scope.row.planNum : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :prop="'schoolExamAcceptLine' ? 'schoolExamAcceptLine' : '-'"
                  label="校考合格线"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.schoolExamAcceptLine
                        ? scope.row.schoolExamAcceptLine
                        : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :prop="'admissionFormula' ? 'admissionFormula' : '-'"
                  label="录取公式"
                  width="250"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.admissionFormula
                        ? scope.row.admissionFormula
                        : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :prop="'dataDesc' ? 'dataDesc' : '-'"
                  label="说明"
                  width="350"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.dataDesc ? scope.row.dataDesc : "-"
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 4" class="content">

        <Exam></Exam>
        <schoolExam></schoolExam>
    
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryById2,
  collect,
  isCollect,
  enrollmentInformation,
  enrollmentPlan,
  numberOfApplicants,
  filingSituation,
  institutionInformation1,
  admissionRequirements,
  institutionStyle,
  getuserlist,
  getExamData,
  getMajorDetail,
  getArtMajorList,
  province,
} from "@/api/index";
import MercuryChart from "@/components/MercuryChart/index.vue";
import Nodata from "@/components/NoData/index.vue";
import Exam from "./exam/index.vue";
import schoolExam from "./schoolExam/index.vue";
export default {
  name: "CollegeDetail",
  components: {
    Nodata,
    MercuryChart,
    Exam,
    schoolExam,
  },
  data() {
    return {
      detailList: {},
      mainIndex: 1,
      isConcern: false,
      echartsData1: [],
      echartsData2: [],
      tableData: [],
      tableData2: [],
      enrollList: [],
      imgList: [],
      schoolexamInfo: "",
      examSubject: "",
      collationExaminationStu: false,
      collationExaminationStu1: false,
      majorList: [],
      tableData1: [],
      culScore: "",
      majorScore: "",
      majorList1: [],
      ruler: "",
      showDetail: 0,
      tableFilters: [],
    };
  },
  mounted() {
    this.getData();
    this.getisCollect();
    this.getEnrollmentInformation();
    this.getEnrollmentPlan(this.$route.query.id);
    this.getNumberOfApplicants(this.$route.query.id);
    this.getFilingSituation(this.$route.query.id);
    this.getAdmissionRequirements(this.$route.query.id);
    this.getSchoolImg();
    this.getMajorList();
    this.getData1();
    this.getMajorDetail1();
    this.getExamData1();
  },
  methods: {
    // 表单删选
    filterHandler(value, row, column) {
      return row.yearly.value === value;
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter("date");
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    // 艺术专业详情展开收起
    unfold(value) {
      if (value == this.showDetail) {
        this.showDetail = 0;
      } else {
        this.showDetail = value;
      }
    },
    getExamData1() {
      let studentDetail = JSON.parse(localStorage.getItem("studentDetail"));

      let province = studentDetail.province;
      let firstMajor = studentDetail.firstMajor;
      let artWard = studentDetail.artWard;
      let subWard = studentDetail.subWard;
      let college = this.$route.query.id;

      if (subWard === null) {
        console.log(college, "qwer");

        getExamData(province, firstMajor, college, artWard).then((res) => {
          if (res.code == 200) {
            this.majorList1 = res.data.batch;
            this.ruler = res.data.rule;
          }
        });
      } else {
        console.log(college, "qwer");
        getExamData(
          province,
          firstMajor,
          college,
          artWard,
          subWard,
          college
        ).then((res) => {
          if (res.code == 200) {
            this.majorList1 = res.data.batch;
            this.ruler = res.data.rule;
          }
        });
      }
    },
    getData1() {
      this.culScore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.cultureScore;
      this.majorScore = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.majorScore;
    },
    //获取专业录取详情
    getMajorDetail1() {
      let college = this.$route.query.id;
      let province = JSON.parse(localStorage.getItem("province"))?.id;

      let majorDirection = JSON.parse(
        localStorage.getItem("studentDetail")
      )?.artWard;
      let score = JSON.parse(localStorage.getItem("studentDetail"))?.mocScore;

      let search = `@college=${college} @province=${province}   @majorDirection=${majorDirection}`;
      let data = {
        search,
        pageSize: 99,
      };
      getMajorDetail(data).then((res) => {
        if (res.code == 200) {
          this.tableData1 = res.data.rows;
          this.tableData1.forEach((item) => {
            item.yearly = {
              text: item.yearly,
              value: item.yearly,
            };
            item.score = score;
          });
          this.tableFilters = [
            ...new Set(this.tableData1.map((item) => item.yearly.value)),
          ].map((value) => ({ text: value, value }));
        }
      });
    },
    // 跳转专业详情页面
    toMajor(data) {
      this.$router.push({
        path: "/majordetail",
        query: { id: data },
      });
    },
    // 获取专业列表
    getMajorList() {
      let college = this.$route.query.id;
      // let college = "1001630";
      let province = JSON.parse(localStorage.getItem("userList"))?.province;
      // let province = "10300000000006";
      let artWard = JSON.parse(localStorage.getItem("studentDetail"))?.artWard;
      let subWard = JSON.parse(localStorage.getItem("studentDetail"))?.subWard;
      if (subWard === null) {
        getArtMajorList(college, province, artWard).then((res) => {
          if (res.code == 200) {
            this.majorList = res.data;
          }
        });
      } else {
        getArtMajorList(college, province, artWard, subWard).then((res) => {
          if (res.code == 200) {
            this.majorList = res.data;
          }
        });
      }
    },
    // 跳转官网
    toWeb(data) {
      window.open(data, "_blank");
    },
    collClick(value) {
      if (value == 1) {
        this.collationExaminationStu = !this.collationExaminationStu;
        // this.collationExaminationStu1 = false;
      } else {
        this.collationExaminationStu1 = !this.collationExaminationStu1;
        // this.collationExaminationStu = false;
      }
      // this.collationExaminationStu = !this.collationExaminationStu;
    },
    // 获取院校风采
    getSchoolImg() {
      console.log(this.$route.query.id);
      let id = this.$route.query.id;
      let search = `@college=${id}`;
      let data = {
        search,
      };
      institutionStyle(data).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data.rows;
        }
      });
    },
    getisCollect() {
      let college = this.$route.query.id;
      isCollect(college).then((res) => {
        this.isConcern = res.data;
      });
    },
    addSchool(data) {
      let college = data;
      collect(college).then((res) => {
        if (res.code == 200) {
          this.getisCollect();
        } else if (res.code == 403) {
          this.$store.commit("setLoginState", { state: true, islogin: false });
        }
      });
    },
    tab(index) {
      this.mainIndex = index;
    },
    getData() {
      let recordId = this.$route.query.id;
      let student = JSON.parse(localStorage.getItem("studentDetail"))?.recordId;
      queryById2(recordId,student).then((res) => {
        this.detailList = res.data;
        this.schoolexamInfo = res.data?.schoolexamInfo;
        this.examSubject = res.data?.examSubject;
      });
    },
    getEnrollmentInformation() {
      let id = this.$route.query?.id;
      let search = `@college=${id}`;
      let data = {
        search,
      };
      institutionInformation1(data).then((res) => {
        if (res.code === 200) {
          this.enrollList = res.data.rows;
        }
      });
    },
    detailClick(recordId) {
      window.open(`/enrolldetail?id=${recordId}`, "_blank");
    },
    getEnrollmentPlan(college1) {
      let search = `@college=${college1}`;
      let data = {
        search,
      };
      enrollmentPlan(data).then((res) => {
        if (res.code === 200) {
          this.echartsData1 = res.data.rows;
        }
      });
    },
    getNumberOfApplicants(college1) {
      let search = `@college=${college1}`;
      let data = {
        search,
      };
      numberOfApplicants(data).then((res) => {
        if (res.code === 200) {
          this.echartsData2 = res.data.rows;
        }
      });
    },
    getFilingSituation(college) {
      filingSituation(college).then((res) => {
        if (res.code === 200) {
          this.tableData2 = res.data;
        }
      });
    },
    getAdmissionRequirements(college) {
      let student = JSON.parse(localStorage.getItem("userList"))?.recordId;
      admissionRequirements(college, student).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
  computed: {
    options1() {
      return {
        title: {
          subtext: "（人数）",
          subtextAlign: "left",
          left: "20px",
          top: "-5px",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
          },
          data: this.echartsData1?.map((item) => item?.year),
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: 40,
          bottom: 30,
          left: 70,
          right: 30,
        },
        series: [
          {
            data: this.echartsData1?.map((item) => item?.planNum),
            type: "line",
            itemStyle: {
              color: "#ef5a2b", // 设置线条颜色
            },
            symbol: "circle", // 设置为圆形
            symbolSize: 10, // 设置圆点大小
          },
        ],
      };
    },
    options2() {
      return {
        title: {
          subtext: "（人数）",
          subtextAlign: "left",
          left: "20px",
          top: "-5px",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
          },
          data: this.echartsData2?.map((item) => item?.year),
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: 40,
          bottom: 30,
          left: 70,
          right: 30,
        },
        series: [
          {
            data: this.echartsData2?.map((item) => item?.enrollNum),
            type: "line",
            itemStyle: {
              color: "#ef5a2b", // 设置线条颜色
            },
            symbol: "circle", // 设置为圆形
            symbolSize: 10, // 设置圆点大小
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.collegedetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 1280px;
    height: 223px;
    margin-top: 30px;
    margin-bottom: 20px;
    // background: linear-gradient(86deg, #ffffff 0%, #ffffff 48%, #ff8ac0 100%);
    background: url(../../../assets/images/collegedetail/back.png);
    border-radius: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    .img {
      width: 135px;
      height: 135px;
      margin: 45px 40px;
      // background: #ccc;
      // border-radius: 50%;
      img {
        // width: 135px;
        height: 135px;
        // margin: 45px 40px;
        background: #ccc;
        // border-radius: 50%;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0px;
      color: white;
      div {
        height: 45px;
        line-height: 45px;
      }
      .name {
        font-size: 21px;
        // color: #333;
        font-weight: bold;
      }
      .label,
      .info {
        display: flex;
        span {
          padding: 3px 10px 3px 0;
          // color: #707070;
          font-size: 18px;
        }
      }
    }
    .add {
      user-select: none;
      cursor: pointer;
      width: 135px;
      height: 50px;
      border: 0;
      background: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 50px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }
  .main {
    width: 1280px;
    margin-bottom: 60px;
    user-select: none;
    .top {
      width: 1280px;
      margin-bottom: 20px;
      .tab {
        display: flex;
        .label {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          background: #ef5a2b;
          color: #fff;
          // margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
        .label1 {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          // background: #fed7e9;
          //color: #fff;
          // color: #ef5a2b;
          // margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
      }
      .line {
        width: 1280px;
        height: 1px;
        border-top: 1px solid #ef5a2b;
      }
    }
    .content {
      width: 1280px;
      // display: flex;
      .card {
        width: 1220px;
        // min-height: 500px;
        border-radius: 20px;
        background: #fff;
        padding: 30px;
        margin-top: 20px;

        .card_group_title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }
        }
      }
      .tips {
        width: 1280px;
        margin-top: 20px;
        span {
          line-height: 2;
        }
      }
      .left {
        width: 1220px;
        // min-height: 500px;
        border-radius: 20px;
        background: #fff;
        padding: 30px;

        // display: flex;
        //margin-right: 32px;
        .briefIntroduction {
          width: 100%;
          // width: 380px;
          height: 240px;
          display: flex;
          ::v-deep .carousel {
            .el-carousel__indicators {
              width: 200px;
              .el-carousel__button {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #fff;
                border: 2px solid #fff;
                transition: background-color 0.3s ease;
              }
              .el-carousel__button.is-active {
                background-color: #409eff;
              }
            }
          }
          .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 240px;
            height: 240px;
            margin: 0;
          }

          .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
          }

          .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
          }
        }

        .labs {
          width: 820px;
          height: 240px;
          margin-left: 20px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0px 0px 20px 20px;
          .leb {
            width: 240px;
            height: 66px;
            border-radius: 10px;
            margin-bottom: 20px;
            padding-left: 20px;
            background: #feecf4;
            display: flex;
            align-items: center;

            cursor: pointer;

            img {
              margin-right: 14px;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .leb1 {
            width: 340px;
            height: 68px;
            background: #feece4;
            border-radius: 10px 10px 10px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            font-size: 18px;
            cursor: pointer;
            .lab1-left {
              display: flex;
              align-items: center;
              .img {
                width: 43px;
                height: 43px;
                background: #ef5b2b;
                border-radius: 50%;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                margin-right: 5px;
              }
            }
          }
        }

        .card_img {
          // width: 1240px;
          width: 1220px;
          // height: 100%;
          border-radius: 20px;
          padding: 30px 0 0 0;

          .card_group_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #242424;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }
          }
          .card_list {
            .el-carousel__container {
              height: 400px;
            }
            .el-carousel__item h3 {
              color: #475669;
              font-size: 14px;
              opacity: 0.75;
              line-height: 400px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
          .cardInfo {
            width: 100%;
            display: flex;
            flex-direction: column;
            .wangzhi {
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 16px;
            }
          }
        }
        .card_group {
          width: 1220px;
          height: 100%;
          border-radius: 20px;
          // padding: 30px;

          .chart-container {
            width: 100%;
            height: 400px;
          }

          .card_group_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #242424;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            position: relative;

            img {
              margin-right: 10px;
            }

            .card_group_title_right {
              position: absolute;
              right: 10px;
              color: #ef5a2b;
              font-family: Source Han Sans SC, Source Han Sans SC;
            }
          }
          .card_group_content_text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #373737;
            margin-bottom: 10px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          .card_group_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #373737;
            text-align: left;
            font-style: normal;
            text-transform: none;
            width: 100%;
            overflow: hidden;

            ::v-deep .el-table__header > .has-gutter {
              color: #373737;
              font-size: 18px;
              font-weight: 500;
            }

            .card_group_content_link {
              width: 100%;
              height: 80px;
              color: #373737;
              display: flex;
              align-items: center;
              justify-content: space-between; /* 确保子元素之间有空间 */
              border-bottom: 1px solid #bfbfbf;
              cursor: pointer;

              &:hover {
                color: #ef5a2b;
              }

              &:last-child {
                border-bottom: none;
              }

              .card_group_content_link_title {
                flex: 20;
              }

              .card_group_content_link_time {
                flex: 2;
              }

              .card_group_content_link_right {
                flex: 1;
              }
            }
          }
        }

        .left-main {
          width: 1220px;
          max-height: 325px;
          overflow-y: scroll;
          scrollbar-color: white;
          scrollbar-color: white;
          padding-bottom: 20px;
          div {
            margin: 0px 30px 0px 30px;
            overflow: hidden;
            ::v-deep p {
              margin-bottom: 10px;
              text-indent: 2rem;
              line-height: 30px;
            }
          }
        }
      }
      .artList {
        margin-top: 20px;
        width: 1280px;
        height: auto;
        background: #ffffff;
        border-radius: 20px 20px 20px 20px;
        .card_group_title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          // margin-bottom: 20px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 30px;
          img {
            margin-right: 10px;
          }
        }
        .artList-content {
          height: auto;
          width: 1200px;
          padding: 25px 30px;
          // border-top: 1px solid #a5a4a4;
          position: relative;

          .artList-content-title {
            font-size: 20px;
            font-weight: 500;
            position: absolute;
            top: 0px;
            padding-bottom: 30px;
            color: #ef5b2b;
            // left: -10px;
          }
          .artList-content-item {
            // height: 150px;
            margin-top: 20px;
            margin-left: 20px;
            padding-bottom: 20px;
            border-bottom: #a5a4a4 1px dashed;
            position: relative;
            &:last-child {
              border-bottom: none;
            }
            .top1 {
              display: flex;
              align-items: center;
              font-size: 18px;
              margin-bottom: 10px;
              // cursor: pointer;
              div:first-child {
                margin-left: 20px;
              }
              span {
                color: #aeaeae;
                border: 1px solid #aeaeae;
                padding: 3px 10px;
                border-radius: 5px;
                margin-right: 10px;

                &:nth-of-type(1) {
                  margin-left: 20px;
                }
              }
            }
            .artList-content-item-bottom {
              display: flex;
              height: auto;
              width: 1220px;
              flex-wrap: wrap;
              div {
                margin-right: 20px;
                margin-top: 10px;
               
              }
            }
            .artList-content-item-detail {
              width: 1180px;
              margin-top: 20px;
              line-height: 30px;
              text-indent: 2em;
            }
            .artList-content-item-detail ::v-deep p {
              img {
                width: 100%;
              }
            }
            .artList-content-item-unfold {
              color: #ef5b2b;
              position: absolute;
              right: 10px;
              top: 0;
            }
          }
        }
      }
      .score {
        margin-top: 20px;
        width: 1280px;
        height: auto;
        background: #ffffff;
        border-radius: 20px 20px 20px 20px;
        .card_group_title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 30px 30px 0 30px;
          img {
            margin-right: 10px;
          }
        }
        .score-content {
          width: 1220px;
          padding: 0 30px 30px 30px;
          display: flex;
          flex-direction: column;
          .box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
        }
      }
      .bottom {
        margin-top: 20px;
        width: 1280px;
        height: auto;
        background: #ffffff;
        border-radius: 20px 20px 20px 20px;
        .card_group_title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 30px 30px 0 30px;
          img {
            margin-right: 10px;
          }
        }
        .info {
          width: 1220px;
          padding: 0 30px 30px 30px;
          display: flex;
          flex-direction: column;
          span {
            margin-bottom: 10px;
            &:first-child {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>