
import requests from './request'
// 获取验证码
export const sms = (phone) => requests({ url: `login/sms/${phone}`, method: 'get', })
// 手机登录
export const login = (data) => requests({ url: 'login/phoneLogin', method: "post", data })
// 会员卡登录
export const login1 = (data) => requests({ url: 'login/cardLogin', method: "post", data })
// 学生信息列表
export const getuserlist = (userInfo) => requests({ url: 'student/list' + `?userInfo=${userInfo}`, method: "get", })
// 高考成绩填报
export const studentEdit = (data) => requests({ url: 'student/edit', method: "put", data })
// 省份列表接口
export const province = () => requests({ url: 'province/list', method: 'get' })
// 报考年份接口
export const yearly = () => requests({ url: 'yearly/list?yearState=1', method: 'get' })
// 科目信息接口
export const subject = () => requests({ url: 'subject/list', method: 'get' })
// 艺考方向/专业类别接口
export const artexam = (province) => requests({ url: 'artexam/tree?province=' + `${province}`, method: 'get' })
// 批次接口
export const batchList = (provinceId, yearlyId, firstMajorId, artWardId, subWardId) => requests({ url: '/batch/fillBatch?provinceId=' + `${provinceId}` + '&yearlyId=' + `${yearlyId}` + '&firstMajorId=' + `${firstMajorId}` + '&artWardId=' + `${artWardId}` + '&subWardId=' + `${subWardId}`, method: 'get' })
// 个人偏好查询接口
export const queryByStuIdAndBatId = (studentId, batchId) => requests({ url: '/intention/queryByStuIdAndBatId?studentId=' + `${studentId}` + '&batchId=' + `${batchId}`, method: 'get' })
// 个人偏好专业列表查询接口
export const fillMajor = ({ eduLevelId, artWardId, subWardId }) => requests({ url: '/major/fillMajor?eduLevelId=' + `${eduLevelId}` + '&artWardId=' + `${artWardId}` + '&subWardId=' + `${subWardId}`, method: 'get' })
// 个人偏好保存接口
export const saveMajor = (data) => requests({ url: '/intention/edit', method: 'put', data })
// 个人偏好添加接口
export const addMajor = (data) => requests({ url: '/intention/add', method: 'post', data })
// 志愿生成接口
export const generate = ({ studentId, batchId, schoolCount }) => requests({ url: '/zhiyuan/generate?batchId=' + `${batchId}` + '&studentId=' + `${studentId}` + '&schoolCount=' + `${schoolCount}`, method: 'get' })
// 获取专业组接口 /zhiyuanGroup/generate
export const getMajorGroup = (studentId, batchId, schoolCount, majorCount, subjectCount) => {
    let url = '/zhiyuanGroup/generate?batchId=' + `${batchId}` + '&studentId=' + `${studentId}` + '&schoolCount=' + `${schoolCount}&majorCount=` + `${majorCount}`;
    if (subjectCount !== null) {
        url += '&subjectCount=' + `${subjectCount}`;
    }
    return requests({ url, method: 'get' });
}
// 志愿查询接口
// export const lookgenerate = (key) => requests({ url: `/zhiyuan/queryGenerateInfo?key=${key}`, method: 'get' })
// 查询字典接口/dict/list?dictNbr
export const getdictNbr = (dict) => requests({ url: '/dict/list?dictNbr=' + `${dict}`, method: 'get' })
// 列表上移
export const upList = ({ key, index }) => requests({ url: '/zhiyuan/moveItemUp?key=' + `${key}` + '&index=' + `${index}`, method: 'put' })
// 列表下移
export const downList = ({ key, index }) => requests({ url: '/zhiyuan/moveItemDown?key=' + `${key}` + '&index=' + `${index}`, method: 'put' })
// 列表删除
export const deleteList = ({ key, index }) => requests({ url: '/zhiyuan/remove?key=' + `${key}` + '&index=' + `${index}`, method: 'delete' })
// 获取志愿概率接口
export const queryProbTypeCount = (key) => requests({ url: '/zhiyuan/queryProbTypeCount?key=' + `${key}`, method: 'get' })
// 获取省份ip接口 /province/userProvince
export const userProvince = () => requests({ url: '/province/userProvince', method: 'get' })
// 退出登录
export const logout = () => requests({ url: '/login/logout', method: 'delete' })
// 志愿保存接口
export const save = (data) => requests({ url: '/wishing/initSave', method: "post", data })
// 获取志愿表接口 /wishing/detail/queryByWishing?wishingId= GET
export const detailList = (wishingId) => requests({ url: '/wishing/detail/queryByWishing?wishing=' + `${wishingId}`, method: 'get' })

// 获取志愿录取结果 /wishing/queryAdmission?wishingId= GET
export const queryAdmission = (wishingId) => requests({ url: '/wishing/queryAdmission?wishing=' + `${wishingId}`, method: 'get' })

// 获取志愿明细接口 /wishing/queryById?recordId= GET
export const queryById = (recordId) => requests({ url: '/wishing/queryById?recordId=' + `${recordId}`, method: 'get' })
// 获取志愿组明细接口 /wishing/group/queryByWishing?wishing=&order=  志愿详情组查询
export const queryByWishing = (wishingId, order) => {
    let url = '/wishing/group/queryByWishing?wishing=' + `${wishingId}`;
    if (order) {
        url += '&order=' + `${order}`;
    }
    return requests({ url, method: 'get' });
}

// 保存志愿接口  /wishing/detail/save PUT
export const saveWishing = (data) => requests({ url: '/wishing/detail/save', method: 'put', data })
// 保存志愿组接口  /wishing/group/save  PUT
export const saveGroup = (data) => requests({ url: '/wishing/group/save', method: 'put', data })

// 下载接口 /wishing/download?recordId=  GET
export const download = (recordId) => requests({ url: '/wishing/download?recordId=' + `${recordId}`, method: 'get', responseType: 'blob' })

// 模拟填报志愿查询接口  /zhiyuan/planList  GET
export const queryByStuId = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags, probTypeCode,) => requests({ url: '/zhiyuan/planList?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}&probTypeCode=` + `${probTypeCode}`, method: 'get' })

// 模拟填报专业组查询 /zhiyuanGroup/planList
export const queryGroupList = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags, probTypeCode, collegeId) => {
    let url = '/zhiyuanGroup/planList?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}&probTypeCode=` + `${probTypeCode}`;
    if (collegeId) {
        url += `&collegeId=${collegeId}`;
    }
    return requests({ url, method: 'get' });
}

// 获取概率筛选信息 /zhiyuan/planCount GET
export const queryProbTypeCount1 = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags) => requests({ url: '/zhiyuan/planCount?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}`, method: 'get' })

// 获取专业组概率信息 /zhiyuanGroup/planCount GET
export const queryGroupCount = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags) => requests({ url: '/zhiyuanGroup/planCount?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}`, method: 'get' })
// 
// 获取保存志愿id  /wishing/add 
export const add = (data) => requests({ url: '/wishing/add', method: 'post', data })

// 保存志愿记录 /wishing/detail/batchAdd
export const batchAdd = (data) => requests({ url: '/wishing/detail/batchAdd', method: 'post', data })
// 保存志愿组记录/wishing/group/batchAdd  POST
export const groupBatchAdd = (data) => requests({ url: '/wishing/group/batchAdd', method: 'post', data })
// 获取志愿表信息l列表 /wishing/queryByStudent？student=

export const queryByStudent = (student) => requests({ url: '/wishing/queryByStudent?student=' + `${student}`, method: 'get' })

// 获取志愿表数量 /wishing/countByStudent？student=
export const countByStudent = (student) => requests({ url: '/wishing/countByStudent?student=' + `${student}`, method: 'get' })

// 学生详情接口 /student/queryById?recordId=  GET
export const queryById1 = (recordId) => requests({ url: '/student/queryById?recordId=' + `${recordId}`, method: 'get' })

// 删除志愿  /wishing/deleteById?recordId= Delete
export const deleteWishing = (data) => requests({ url: '/wishing/deleteById', method: 'delete', data })

// 查询关注数量  /college/CollectqueryCollectCount?userInfo= GET
export const CollectqueryCollectCount = (userInfo) => requests({ url: '/collegeCollect/queryCollectCount?userInfo=' + `${userInfo}`, method: 'get' })
// 查大学列表 /college/page  GET
export const collegeList = (collegeName, eduLevel, natureType, province, collegeClass, featureTags, pageNum, isUnifiedExam, isSchoolExam, studentId) => {
    let url = '/college/page?collegeName=' + `${collegeName}&eduLevel=` + `${eduLevel}&natureType=` + `${natureType}&province=` + `${province}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}&pageNum=` + `${pageNum}&isUnifiedExam=` + `${isUnifiedExam}&isSchoolExam=` + `${isSchoolExam}&` + `isArt=1`;
    if (studentId) {
        url += `&studentId=${studentId}`;
    }
    return requests({ url, method: 'get' });
}
// 院校对比接口 /college/contrast  GET recordId  yearly
export const contrast = (recordId, yearly) => requests({ url: '/college/contrast?recordId=' + `${recordId}&yearly=` + `${yearly}`, method: 'get' })

// 查专业接口 /major/queryAllSubMajor eduLevelId majorName
export const queryMajor = (eduLevelId, majorName, majorTypeCode) => requests({ url: '/major/queryAllSubMajor?eduLevelId=' + `${eduLevelId}&majorName=` + `${majorName}&majorTypeCode=` + `${majorTypeCode}`, method: 'get' })

// 修改志愿表名字 /wishing/edit PUT
export const updateWishing = (data) => requests({ url: '/wishing/edit', method: 'put', data })
// 大学详情接口 /college/queryById?recordId= GET
export const queryById2 = (recordId, student) => {
    let url = '/college/queryById?recordId=' + `${recordId}`;
    if (student) {
        url += `&student=${student}`;
    }
    return requests({ url, method: 'get' });
} 
// 添加关注接口  /collegeCollect/collect?userInfo=&college=
export const collect = (college) => requests({ url: '/collegeCollect/collect?college=' + `${college}`, method: 'get' })
// 查询该院校是否关注  /collegeCollect/isCollect?&college=
export const isCollect = (college) => requests({ url: '/collegeCollect/isCollect?college=' + `${college}`, method: 'get' })

// 校考院校查询接口 /studentSchoolExam/querySchoolExamCollegeList?student= GET 
export const queryCollege = (student) => requests({ url: `/studentSchoolExam/querySchoolExamCollegeList?student=${student}`, method: 'get' })
// /studentSchoolExam/querySchoolExamMajorList?college=&student= GET查询院校专业列表
export const queryMajorXiao = (college, student) => requests({ url: '/studentSchoolExam/querySchoolExamMajorList?college=' + `${college}&student=` + `${student}`, method: 'get' })
// 校考信息查询接口/studentSchoolExam/queryStudentSchoolExamList get 
export const queryByStudentXiao = (student) => requests({ url: '/studentSchoolExam/queryStudentSchoolExamList?student=' + `${student}`, method: 'get' })

//删除校考信息接口 /studentSchoolExam/removeById recordId  DElETE  
export const deleteById = (data) => requests({ url: '/studentSchoolExam/removeById', data, method: 'delete' })

// 添加校考信息接口 /xiaoKaoScore/add post
export const addXiaoKao = (data) => requests({ url: '/xiaoKaoScore/add', method: 'post', data })

// 获取专业详情的接口 /major/queryById?recordId= GET
export const queryById3 = (recordId) => requests({ url: '/major/queryById?recordId=' + `${recordId}`, method: 'get' })
// 专业开设院校 /api/major/queryOpenCollege 
export const queryOpenCollege = (majorId, pageNum, pageSize) => requests({ url: '/major/queryOpenCollege?majorId=' + `${majorId}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}`, method: 'get' })

// 公用接口
export const commonInterface = (aipId, data) => requests({ url: `/oridata/${aipId}/list`, data, method: 'post' })

//查询招生信息列表
export const enrollmentInformation = (data) => requests({ url: '/oridata/21000640/list', data, method: 'post' })

//查询招生详情
export const enrollmentDetail = (data) => requests({ url: '/oridata/21000640/detail', data, method: 'post' })
// 查询院校详情院校风采接口
export const institutionStyle = (data) => requests({ url: '/oridata/21000648/list', data, method: 'post' })

//查询院校资讯列表   /college/other/queryHomeCollegeEnrollment?province=&pageNum=&pageSize= GET

export const institutionInformation = ({ province, pageNum, pageSize }) => requests({ url: `/college/other/queryHomeCollegeEnrollment?province=${province}&pageNum=${pageNum}&pageSize=${pageSize}`, method: 'get' })

// 查询院校招生信息接口
export const institutionInformation1 = (data) => requests({ url: '/oridata/21000639/list', data, method: 'post' })

//查询院校资讯详情
export const informationDetail = (data) => requests({ url: '/oridata/21000639/detail', data, method: 'post' })

// 近5年招生计划数的接口 /college/count/yearlyEnrollmentPlan?collegeId=  GET

export const enrollmentPlan = (data) => requests({ url: '/oridata/21000661/list', data, method: 'post' })

// 近5年校考报名人数的接口 /college/count/yearlyEnrollmentPlan?collegeId=  GET
export const numberOfApplicants = (data) => requests({ url: '/oridata/21000663/list', data, method: 'post' })

// 历年投档情况的接口 /college/count/yearlyAllocationPlan?college= GET
export const filingSituation = (college) => requests({ url: '/college/count/yearlyAllocationPlan?college=' + `${college}`, method: 'get' })

// 职业分布的接口 /major/count/occupationalDist?major= GET
export const occupationalDistribution = (major) => requests({ url: '/major/count/occupationalDist?major=' + `${major}`, method: 'get' })

// 薪资分布的接口 /major/count/salaryDist?major= GET
export const payDistribution = (major) => requests({ url: '/major/count/salaryDist?major' + `${major}`, method: 'get' })

// 就业分布的接口 /major/count/employmentDist?major= GET
export const employmentDistribution = (major) => requests({ url: '/major/count/employmentDist?major' + `${major}`, method: 'get' })
// mbti 问题接口 /psytest/queryQuestion/mbit   GET  获取mbti题目
export const queryQuestion = () => requests({ url: '/psytest/queryQuestion/mbit', method: 'get' })
// mbti 提交接口 /psytest/queryAnswer/mbit POST
export const queryAnswer = (data) => requests({ url: '/psytest/queryAnswer/mbit', method: 'post', data })

// mbti 保存结果接口 /psytest/record/save POST
export const MbtiSave = (data) => requests({ url: '/psytest/record/save', method: 'post', data })

// 专业招生要求接口 /collegeEnrollRequire/queryEnrollReq?student=259442276172824576&college=1000003 GET
export const admissionRequirements = (college, student) => requests({ url: `/collegeEnrollRequire/queryEnrollReq?student=${student}&college=` + `${college}`, method: 'get' })

// 微信二维码接口 /order/pay/createOrder POST 
export const qrCode = (data) => requests({ url: '/order/wx/pay/createOrder', method: 'post', data })
// 查询订单状态 /oridata/{entityId}/detail PSOT {"recordId":""}
export const orderStatus = (data) => requests({ url: '/oridata/21000649/detail', method: 'post', data })

//算投档 /comscoreRule/scoreCal?province=&artWard=&subWard=&cultureScore=&majorScore
export const arithmeticFile = (province, artWard, subWard, cultureScore, majorScore) => requests({ url: '/comscoreRule/scoreCal?province=' + `${province}&artWard= + ${artWard}&subWard= + ${subWard}&cultureScore= + ${cultureScore}&majorScore= + ${majorScore}`, method: 'get' })

//文化位次 /segment/rankCal?province=&firstMajor=&score=
export const rankSelect = (province, firstMajor, score) => requests({ url: '/segment/rankCal?province=' + `${province}&firstMajor= + ${firstMajor}&score= + ${score}`, method: 'get' })

//专业位次 /artSegment/rankCal?provinceId=&artWardId=&subWardId=&score=
export const artRankSelect = (province, artWard, subWard, score) => requests({ url: '/artSegment/rankCal?province=' + `${province}&artWard= + ${artWard}&subWard= + ${subWard}&score= + ${score}`, method: 'get' })

// 热门院校
export const getPopularSchools = ({ province, eduLevel, excludeProvince, pageNum, pageSize, isArt, isHome, studentId }) => {
    let url = `/college/page?province=${province}&eduLevel=${eduLevel}&excludeProvince=${excludeProvince}&pageNum=${pageNum}&pageSize=${pageSize}&isArt=${isArt}&isHome=${isHome}`;
    if (studentId) {
        url += `&studentId=${studentId}`;
    }
    return requests({ url, method: 'get' });
}
// 院校详情艺术专业列表  /major/queryOpenMajor？college= GET
export const getArtMajorList = (college, province, artWard, subWard) => {
    let url = `/major/queryOpenMajor?college=${college}&province=${province}&artWard=${artWard}`;
    if (subWard) {
        url += `&subWard=${subWard}`;
    }
    return requests({ url, method: 'get' });
}

// 录取专业详情 /college/queryCollegePlan?college=  GET
export const getMajorDetail = (data) => requests({ url: '/oridata/21000658/list', method: 'post', data })
// 考试院投档信息 /college/queryBatchDataLine?province=&firstMajor=&artWard=&subWard=
export const getExamData = (province, firstMajor, college, artWard, subWard,) => {
    let url = `/college/queryBatchDataLine?province=${province}&firstMajor=${firstMajor}&artWard=${artWard}&college=${college}`;
    console.log(college, 1234);

    if (subWard) {
        url += `&subWard=${subWard}`;
    }
    return requests({ url, method: 'get' });
}

// 录取查询接口  /wishing/queryAdmission?student=&wishing=&yearly= GET
export const getAdmission = (student, wishing, yearly) => requests({ url: `/wishing/queryAdmission?student=${student}&wishing=${wishing}&yearly=${yearly}`, method: 'get' })

// 查询个人中心测评记录列表接口 /psytest/record/queryByStudent?student= GET

export const getTestRecord = (student) => requests({ url: `/psytest/record/queryByStudent?student=${student}`, method: 'get' })

// 从个人中心跳转查询测评记录列表接口  /psytest/record/queryAnswer?student=&typeGroup= get
export const getTestRecordDetail = (student, typeGroup) => requests({ url: `/psytest/record/queryAnswer?student=${student}&typeGroup=${typeGroup}`, method: 'get' })
// 专业详情页面院校招生详情  /major/queryRecruitCollege?college=&province=
export const getMajorCollege = (major, province, pageSize, pageNum) => requests({ url: `/major/queryRecruitCollege?major=${major}&province=${province}&pageSize=${pageSize}&pageNum=${pageNum}`, method: 'get' })
// 校考日历正在报名查询接口 /schoolExamCalendar/queryApplyingList
export const getApplyingList = (date) => requests({ url: `/schoolExamCalendar/queryApplyingList?date=${date}`, method: 'get' })
// 校考日历线上考点查询接口 /schoolExamCalendar/queryOnlineList
export const getOnlineList = (date) => requests({ url: `/schoolExamCalendar/queryOnlineList?date=${date}`, method: 'get' })
// 校考日历线下考点查询接口 /schoolExamCalendar/queryOfflineList
export const getOfflineList = (date) => requests({ url: `/schoolExamCalendar/queryOfflineList?date=${date}`, method: 'get' })

// 获取院校详情录取分析招生方向列表/college/analysis/queryEnrollmentDirection GET 
export const getEnrollmentDirection = (college, student) => requests({ url: `/college/analysis/queryEnrollmentDirection?college=${college}&student=${student}`, method: 'get' })
// 获取录取分析校考详情的接口 /college/analysis/queryEnrollmentDirectionDetail?enrollDirectionId= GET
export const queryEnrollmentDirectionDetail = (enrollDirectionId, studentId) => requests({ url: `/college/analysis/queryEnrollmentDirectionDetail?enrollDirectionId=${enrollDirectionId}&studentId=${studentId}`, method: 'get' })

// 录取分析页面添加校考接口 /studentSchoolExam/add POST
export const addStudentSchoolExam = (data) => requests({ url: '/studentSchoolExam/add', method: 'post', data })

// 录取分析页面统考列表接口 /college/analysis/queryAdmissionAnalysis?college=1000008&student=235997326622064640
export const queryAdmissionAnalysis = (college, student) => requests({ url: `/college/analysis/queryAdmissionAnalysis?college=${college}&student=${student}`, method: 'get' })


// 录取分析页面统考学校详情 /college/analysis/queryAdmissionAnalysisDetail?enrollDirectionId=&studentId=
export const queryAdmissionAnalysisDetail = (enrollDirectionId, studentId) => requests({ url: `/college/analysis/queryAdmissionAnalysisDetail?enrollDirectionId=${enrollDirectionId}&studentId=${studentId}`, method: 'get' })
