<template>
  <div class="informationList">
    <div class="card_group_title_icon">
      <img src="@/assets/images/collegedetail/icon_title.png"/>
      {{type === "dynamic" ? "高考动态" : "院校资讯"}}
    </div>
    <div class="dataList">
      <div class="list_card">
        <div
            class="list_card_item"
            v-for="item in dataList"
            :key="item.recordId"
            @click="detailClick(item.recordId, type)">
          <div class="list_card_item_title">{{item.title}}</div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="list_pagination" v-show="dataList.length !== 0">
        <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageOptions.pageSize"
            @current-change="handlePageChange"
            :total="pageOptions.total">
        </el-pagination>
      </div>
      <Nodata v-if="dataList.length === 0" />
    </div>
  </div>
</template>

<script>

import {enrollmentInformation, institutionInformation} from "@/api";
import Nodata from "@/components/NoData/index.vue";

export default {
  name: "InformationList",
  components: {Nodata},
  data() {
    return {
      dynamicStateList: [],
      messageList: [],
      type: "dynamic",
      pageOptions: {
        pageSize: 10,
        pageNum: 1,
        total: 100,
      }
    };
  },
  methods: {
    getData() {
      if(this.type === "dynamic") {
        this.getEnrollmentInformation();
      } else  {
        this.getInstitutionInformation();
      }
    },
    getEnrollmentInformation () {
      let id = JSON.parse(localStorage.getItem('province'))?.id;
      let search = `@province=${id}`
      let data = {pageSize: this.pageOptions.pageSize, pageNum: this.pageOptions.pageNum, search};
      enrollmentInformation(data).then(res => {
        if(res.code === 200) {
          this.dynamicStateList = res.data?.rows;
          this.pageOptions.total = res.data?.total;
        }
      })
    },
    getInstitutionInformation() {
      let id = JSON.parse(localStorage.getItem("province"))?.id 
    
      let data = {pageSize: this.pageOptions.pageSize, pageNum: this.pageOptions.pageNum,province:id};
      institutionInformation(data).then(res => {
        if(res.code === 200) {
          this.messageList = res.data.list;
          this.pageOptions.total = res.data?.size;
        }
      })
    },
    detailClick(recordId, type) {
      window.open(`/enrolldetail?id=${recordId}&&type=${type}`, "_blank");
    },
    handlePageChange(page) {
      this.pageOptions.pageNum = page;
      this.getData();
    },
  },

  computed: {
    dataList() {
      return this.type === 'dynamic' ? this.dynamicStateList : this.messageList;
    }
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.type = urlParams.get('type');
  },

  mounted() {
    this.getData();
  }
}
</script>

<style scoped lang="scss">
.informationList {
  margin: 0px auto;
  width: 1280px;
  //background-color: white;
  padding: 20px;

  .card_group_title_icon {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 24px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 20px;

    img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }


  .dataList {
    width: 100%;

    .list_card {
      width: 100%;
      background-color: white;
      padding: 0 15px;
      box-sizing: border-box;
      cursor: pointer;

      .list_card_item {
        width: 100%;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          color: #ef5a2b;

          .list_card_item_title {
            color: #ef5a2b;
          }

        }

        .list_card_item_title {
          font-size: 18px;
          cursor: pointer;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          color: #242424;
        }

      }

    }

    .list_pagination {
      text-align: center;
      margin-top: 20px;

      ::v-deep {

        .number, .btn-prev {
          width: 32px;
          height: 32px;
        }

        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ef5a2b;
        }

        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ef5a2b;
        }

        .el-pager li {
          line-height: 32px;
        }

      }

    }

  }

}
</style>