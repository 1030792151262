<template>
  <div class="img">
    <span>暂无数据!</span>
  </div>
</template>

<script>
export default {
  name: "nodata",
};
</script>

<style lang="scss" scoped>
.img {
  width: 278px;
  height: 251px;
  background: url("../../assets/images/nodata/nodata.png") no-repeat;
  background-size: contain;
  text-align: center;
  margin: 0 auto;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 50px;
  position: relative;
  span{
    position: absolute;
    top: 215px;
    left: 90px
  }
}
</style>